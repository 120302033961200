import X4 from "@/assets/images/machine-type/x4.png";
import MachineStatus from "@/components/machine-status";

const X4MachineCard = ({ alias, status, classes, translate }) => {
	return (
		<div>
			<div className={classes.machineHeading}>
				<div className={classes.textSmallBold}>{translate("Machine")}</div>
				<div className={`${classes.header2} ${classes.machineAlias}`}>{alias}</div>
				<MachineStatus
					status={status}
					translate={translate}
					centerVertically={classes.centerVertically}
					statusText={classes.machineStatusText}
					machineStatus={classes.machineStatus}
				/>
			</div>
			<img src={X4} style={{ width: "100%", maxWidth: "267px" }} />
		</div>
	);
};

export default X4MachineCard;
