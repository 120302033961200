import { Fragment, lazy, useContext } from "react";
import CartNav, { getAuthorizedCartTabs } from "@/views/cart/cart-nav";
import ConfigurationNav, { getAuthorizedConfigurationTabs } from "@/views/configuration/configuration-nav.jsx";
import CubeNav, { getAuthorizedCubeTabs } from "@/views/cube/cube-nav";
import DashboardNav, { getAuthorizedDashboardTabs } from "@/views/dashboard/dashboard-nav";
import { getAuthorizedDimensioningTabs } from "@/views/dimensioning/dimensioning-nav";
import { Navigate, Outlet, Route, Routes } from "react-router";
import ProductionNav, { getAuthorizedProductionTabs } from "@/views/production/production-nav";
import PsNowNav, { getAuthorizedPsNowTabs } from "@/views/ps-now/ps-now-nav";
import ReportingNav, { getAuthorizedReportingTabs } from "@/views/reporting/reporting-nav";
import VMINav, { getAuthorizedVmiTabs } from "@/views/vmi/vmi-nav";
import CollectWizard from "@/views/dimensioning/collect/collect-wizard";
import EmInstructionList from "@/views/utilities/em-instruction-list";
import FeatureFlags from "@/views/utilities/feature-flags";
import LogOut from "@/views/login/logout";
import OptimalProduction from "@/views/optimal-production/optimal-production-details";
import SiteSelect from "@/views/login/site-select";
import UniversalLogin from "@/views/login/universal-login";
import { UserContext } from "./components/user-context";
import { hasAnyAdminPermission } from "@/views/configuration/roles/role-utils";
import { isDev } from "@/utils/isDev";
import { useIntl } from "react-intl";
import ForgotPassword from "./views/login/forgot-password/forgot-password";
import ResetPassword from "./views/login/forgot-password/reset-password";
import Navbar from "./views/navbar/navbar";
import { LazyRoute } from "@/components/lazy-route";
import SsoError from "@/views/login/sso-error";
import EntityHistoryProvider from "@/contexts/entity-history-provider.jsx";
import OrdersHistory from "@/views/production/orders/history/orders-history";
import SavedJobDependencyProvider from "@/views/production/saved-jobs/saved-job-dependency-provider.jsx";

const EditPackageSolution = lazy(() => import("@/views/production/saved-jobs/views/edit-package-solution.jsx"));
const CreatePackagingSolution = lazy(() => import("@/views/production/saved-jobs/views/create-packaging-solution.jsx"));

const LazyDimensioningNav = lazy(() =>
	import("@/views/dimensioning").then((m) => ({
		default: m.DimensioningNav,
	})),
);
const LazyAbout = lazy(() => import("@/views/about/about"));
const LazyAuditTrail = lazy(() => import("@/views/search/audit-trail"));
const DataMapping = lazy(() =>
	import("@/views/configuration/importing-data/views").then((m) => ({
		default: m.DataMapping,
	})),
);
const LazyDojo = lazy(() => import("@/views/dojo/dojo"));
const ImportSource = lazy(() =>
	import("@/views/configuration/importing-data/views").then((m) => ({
		default: m.ImportSource,
	})),
);
const LazyInvalidAccess = lazy(() => import("@/views/login/invalid-access"));
const PipelineWrapper = lazy(() =>
	import("@/views/configuration/importing-data/views").then((m) => ({
		default: m.PipelineWrapper,
	})),
);
const RulesConfiguration = lazy(() =>
	import("@/views/configuration/importing-data/views").then((m) => ({
		default: m.RulesConfiguration,
	})),
);
const LazySearch = lazy(() => import("@/views/search/search"));
const SelectionAnalyticsDetails = lazy(() =>
	import("@/views/dashboard/dashboard-views").then((m) => ({ default: m.SelectionAnalyticsDetails })),
);
const SelectionHistoryDetails = lazy(() =>
	import("@/views/dashboard/dashboard-views").then((m) => ({ default: m.SelectionHistoryDetails })),
);
const LazySimulators = lazy(() => import("@/views/simulators/simulators"));
const LazySupport = lazy(() => import("@/views/support/support"));
const LazyThemingLayout = lazy(() => import("@/views/utilities/layouts/theming-layout"));
const LazySavedJobs = lazy(() => import("@/views/production/saved-jobs"));

const AppRoutes = () => {
	let { currentUser, setCurrentUser } = useContext(UserContext);
	const intl = useIntl();
	const hasConfigurationRoles = currentUser?.role ? hasAnyAdminPermission(currentUser.role) : false;

	let redirectLink = "/login";
	if (!currentUser || !currentUser.id) redirectLink = "/login";
	else if (currentUser.role?.length === 0) redirectLink = "/invalid-access";
	else if (hasConfigurationRoles) redirectLink = "/configuration";
	else if (currentUser && currentUser["ff-pack-net-production"] === "True") redirectLink = "/production";
	else if (currentUser && currentUser["ff-pack-net-reporting"] === "True") redirectLink = "/reporting";
	else if (currentUser && currentUser["ff-dim-allow"] === "True") redirectLink = "/dimensioning";
	else if (currentUser && currentUser["ff-pack-net-dashboard"] === "True") redirectLink = "/dashboard";
	else redirectLink = "/invalid-access";

	const getRoutesFromTab = (tab) =>
		tab.defaultMenuItem ? (
			<Fragment key={`${tab.link}-fragment`}>
				<Route index element={tab.component} key={`${tab.link}-index`} />
				<Route path={tab.link} element={tab.component} key={tab.link} />
			</Fragment>
		) : (
			<Route path={tab.link} element={tab.component} key={tab.link} />
		);

	//TODO: This seems to be reloading a lot!
	return (
		<Routes>
			<Route exact path="/" element={<Navigate to={redirectLink} />} />
			<Route exact path="/login-2phase" element={<Navigate to={"/login"} />} />
			<Route exact path="/login-2phase-creds" element={<Navigate to={"/login"} />} />
			<Route exact path="/universal-login" element={<Navigate to={"/login"} />} />
			<Route path="/invalid-access" element={<LazyInvalidAccess />} />

			<Route
				exact
				path="/login"
				element={
					<>
						{
							//A logged in user will show the nav bar on login, so redirect them
							currentUser?.id && <Navigate to="/" />
						}
						<UniversalLogin setCurrentUser={setCurrentUser} />
					</>
				}
			/>
			<Route
				exact
				path="/forgot-password"
				element={
					<>
						{
							//A logged in user will show the nav bar on login, so redirect them
							currentUser?.id && <Navigate to="/" />
						}
						<ForgotPassword />
					</>
				}
			/>
			<Route
				exact
				path="/sso-error"
				element={
					<>
						{
							//A logged in user will show the nav bar on login, so redirect them
							currentUser?.id && <Navigate to="/" />
						}
						<SsoError />
					</>
				}
			/>
			<Route exact path="/complete-password-reset" element={<ResetPassword />} />

			<Route
				exact
				path="/logged-out"
				element={
					<>
						{
							//A logged in user will show the nav bar on logged-out, so redirect them
							currentUser?.id && <Navigate to="/" />
						}
						<UniversalLogin sessionExpired={true} />
					</>
				}
			/>
			<Route exact path="/site-select" element={<SiteSelect setCurrentUser={setCurrentUser} />} />

			<Route element={<Navbar />}>
				<Route
					exact
					path="/logout"
					element={
						<>
							{
								//A logged in user will show the nav bar on logout, so redirect them
								currentUser?.id && <Navigate to="/" />
							}
							<LogOut />
						</>
					}
				/>

				<Route path="/support-menu" element={<LazyRoute Component={LazySupport} />} />
				<Route path="/about-menu" element={<LazyRoute Component={LazyAbout} />} />
				<Route path="/optimal-production" element={<OptimalProduction />} />
				{currentUser && currentUser["ff-pack-net-dashboard"] === "True" && (
					<Route path="/dashboard" element={<DashboardNav currentUser={currentUser} />}>
						<Route path="*" element={<Navigate to="/dashboard" />} />
						<Route
							path="selection-analytics-details/:productionGroupId"
							element={<LazyRoute Component={SelectionAnalyticsDetails} />}
						/>
						<Route
							path="selection-history-details/:auditId"
							element={<LazyRoute Component={SelectionHistoryDetails} />}
						/>
						<Route
							path="packaging-solution-audit-trail/:packagingSolutionId"
							element={<LazyRoute Component={LazyAuditTrail} />}
						/>
						{getAuthorizedDashboardTabs(currentUser, intl).map(getRoutesFromTab)}
					</Route>
				)}
				{currentUser && currentUser["ff-pack-net-production"] && (
					<Route path="/production" element={<ProductionNav currentUser={currentUser} />}>
						<Route path="*" element={<Navigate to="/production" />} />
						<Route path="orders/history" element={<OrdersHistory />} />
						{getAuthorizedProductionTabs(currentUser, intl, true).map(getRoutesFromTab)}

						{currentUser.role.some((userRole) =>
							[
								"ManagePackagingSolutions",
								"ModifyAndSendPackagingSolutionToQueue",
								"SendPackagingSolutionToQueue",
								"CreatePackagingSolution",
							]
								.map((r) => r.toLocaleLowerCase())
								.includes(userRole.toLocaleLowerCase()),
						) && (
							<Route
								path="saved-jobs"
								element={
									<EntityHistoryProvider>
										<SavedJobDependencyProvider>
											<Outlet />
										</SavedJobDependencyProvider>
									</EntityHistoryProvider>
								}
							>
								<Route index element={<LazyRoute Component={LazySavedJobs} />} />
								<Route path="create" element={<LazyRoute Component={CreatePackagingSolution} />} />
								<Route path=":id" element={<LazyRoute Component={EditPackageSolution} />} />
							</Route>
						)}
					</Route>
				)}
				{hasConfigurationRoles && (
					<Route
						path="/configuration"
						element={
							<EntityHistoryProvider>
								<ConfigurationNav currentUser={currentUser} />
							</EntityHistoryProvider>
						}
					>
						{currentUser.role.includes("manageimportpipelines") && (
							<Route key="import-pipeline" path="import-pipeline" element={<LazyRoute Component={PipelineWrapper} />}>
								<Route path="import-source" element={<LazyRoute Component={ImportSource} />} />
								<Route path="data-mapping" element={<LazyRoute Component={DataMapping} />} />
								<Route path="rules-configuration" element={<LazyRoute Component={RulesConfiguration} />} />
							</Route>
						)}
						{getAuthorizedConfigurationTabs(currentUser, intl).map(getRoutesFromTab)}
						<Route path="*" element={<Navigate to="/configuration/server-settings" replace />} />
					</Route>
				)}
				{/*this allows for an authenticated but no access to configuration pages state*/}
				{currentUser && currentUser["ff-dim-allow"] === "True" && (
					<Route path="/dimensioning" element={<LazyRoute Component={LazyDimensioningNav} />}>
						<Route path="/dimensioning/collect-wizard" element={<CollectWizard />} />
						{getAuthorizedDimensioningTabs(currentUser, intl).map(getRoutesFromTab)}
					</Route>
				)}
				{currentUser && currentUser["ff-cube-allow"] === "True" && (
					<Route path="/cube" element={<CubeNav currentUser={currentUser} />}>
						{getAuthorizedCubeTabs(currentUser, intl).map(getRoutesFromTab)}
					</Route>
				)}
				{currentUser && currentUser["ff-pack-net-reporting"] === "True" && (
					<Route path="/reporting" element={<ReportingNav currentUser={currentUser} />}>
						{getAuthorizedReportingTabs(currentUser, intl).map(getRoutesFromTab)}
					</Route>
				)}
				{currentUser && currentUser["ff-pack-net-vmi"] === "True" && (
					<Route path="/vmi" element={<VMINav currentUser={currentUser} />}>
						{getAuthorizedVmiTabs(currentUser, intl).map(getRoutesFromTab)}
					</Route>
				)}
				{currentUser && currentUser["ff-PackNet-PS-Now"] === "True" && (
					<Route path="/ps-now" element={<PsNowNav currentUser={currentUser} />}>
						<Route path="*" element={<Navigate to="/ps-now" />} />
						{getAuthorizedPsNowTabs(currentUser, intl).map(getRoutesFromTab)}
					</Route>
				)}
				{currentUser &&
					currentUser["ff-pack-net-cart"] === "True" && ( //TODO: What is the feature flag? Is this at the correct location? Shouldnt it be in Dimensioning?
						<Route path="/cart" element={<CartNav currentUser={currentUser} />}>
							{getAuthorizedCartTabs(currentUser, intl).map(getRoutesFromTab)}
						</Route>
					)}
				{currentUser?.["ff-machines-allow-simulator"] === "True" && (
					<Route exact path="/simulators" element={<LazyRoute Component={LazySimulators} />} />
				)}

				{/* THESE ARE Authorized Routes, the user is logged in */}
				{currentUser && (
					<>
						<Route path="/search" element={<LazyRoute Component={LazySearch} />} />
						<Route exact path="/feature-flags" element={<FeatureFlags currentUser={currentUser} />} />
						<Route exact path="/theming" element={<LazyRoute Component={LazyThemingLayout} />} />
						<Route exact path="/em-instruction-list" element={<EmInstructionList currentUser={currentUser} />} />
						{/* <Route path="/documentation" element={<Documentation currentUser={currentUser}/>} /> */}
					</>
				)}
				{isDev() && <Route exact path="/dojo" element={<LazyRoute Component={LazyDojo} />} />}
			</Route>
		</Routes>
	);
};

export default AppRoutes;
