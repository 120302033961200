import { createContext, useContext, useState } from "react";
import { ProductionContext } from "@/contexts/production-context";
import useAxios from "@/api/useAxios";
import { useQuery } from "@tanstack/react-query";
import useToken from "@/hooks/use-token";
import { baseRoutes } from "@/api/base-routes.js";

export const JobQueueContext = createContext({
	selectedJobs: [],
	selectedBatches: [],
	jobHistory: [],
	jobsLoading: true,
	historyLoading: true,
	batchesLoading: true,
	refetchEnabled: true,
});

function JobQueueProvider({ children }) {
	const token = useToken();
	const { currentMachineGroup } = useContext(ProductionContext);
	const [jobsRefetch, setJobsRefetch] = useState(true);
	const [batchesRefetch, setBatchesRefetch] = useState(true);
	const [historyRefetch, setHistoryRefetch] = useState(true);
	const maxRetries = 3;
	const shortPollingRateMs = 5000;
	const longPollingRateMs = 10000;

	const AuditCurrentStateApi = useAxios(`${baseRoutes.audit}/v1/CurrentState`, token);
	const AuditPackagingSolutionsApi = useAxios(`${baseRoutes.audit}/v1/Audit/PackagingSolutions`, token);

	const selectedJobsQueryFn = async () => {
		const response = await AuditCurrentStateApi.getWithUrl(
			`PackagingSolutions/byStatus/InProgress/byMachineGroup/${currentMachineGroup.id}`,
		);
		return response.data;
	};

	const selectedBatchesQueryFn = async () => {
		const response = await AuditCurrentStateApi.getWithUrl(
			`InProgressBatches/byMachineGroup/${currentMachineGroup.id}`,
		);
		return response.data;
	};

	const jobHistoryQueryFn = async () => {
		const response = await AuditPackagingSolutionsApi.getWithUrl(
			`byMachineGroupHistory/${currentMachineGroup.id}/limit/10/latest`,
		);
		const jobHistory = response.data.map((j) => ({
			...j,
			data: typeof j.data === "string" ? JSON.parse(j.data) : j.data,
			date: new Date(j.createDateUTC),
		}));
		return jobHistory;
	};

	const selectedJobsQueryKey = ["machineGroupJobQueue", { machineGroupId: currentMachineGroup?.id }];
	const selectedBatchesQueryKey = ["machineGroupBatchQueue", { machineGroupId: currentMachineGroup?.id }];
	const jobHistoryQueryKey = ["machineGroupJobHistory", { machineGroupId: currentMachineGroup?.id }];

	const {
		data: selectedJobs,
		isLoading: selectedJobsApiLoading,
		failureCount: jobsFailureCount,
	} = useQuery({
		queryKey: selectedJobsQueryKey,
		queryFn: selectedJobsQueryFn,
		refetchInterval: jobsRefetch ? shortPollingRateMs : false,
		retry: maxRetries,
		enabled: Boolean(currentMachineGroup),
	});

	if (jobsFailureCount > maxRetries && jobsRefetch) setJobsRefetch(false);

	const {
		data: selectedBatches,
		isLoading: selectedBatchesApiLoading,
		failureCount: batchesFailureCount,
	} = useQuery({
		queryKey: selectedBatchesQueryKey,
		queryFn: selectedBatchesQueryFn,
		refetchInterval: batchesRefetch ? shortPollingRateMs : false,
		retry: maxRetries,
		enabled: Boolean(currentMachineGroup),
	});

	if (batchesFailureCount > maxRetries && batchesRefetch) setBatchesRefetch(false);

	const {
		data: jobHistory,
		isLoading: jobHistoryApiLoading,
		failureCount: historyFailureCount,
	} = useQuery({
		queryKey: jobHistoryQueryKey,
		queryFn: jobHistoryQueryFn,
		refetchInterval: historyRefetch ? longPollingRateMs : false,
		retry: maxRetries,
		enabled: Boolean(currentMachineGroup),
	});

	if (historyFailureCount > maxRetries && historyRefetch) setHistoryRefetch(false);

	if (!currentMachineGroup) return <>{children}</>;

	return (
		<JobQueueContext.Provider
			value={{
				jobHistory: jobHistory || [],
				selectedJobs: selectedJobs || [],
				selectedBatches: selectedBatches || [],
				jobsLoading: selectedJobsApiLoading,
				historyLoading: jobHistoryApiLoading,
				batchesLoading: selectedBatchesApiLoading,
				refetchEnabled: jobsRefetch && batchesRefetch && historyRefetch,
			}}
		>
			{children}
		</JobQueueContext.Provider>
	);
}

export default JobQueueProvider;
