import axios from "axios";
import { readLocalStorage } from "./local-storage";
import { baseRoutes } from "./base-routes";

export async function createUpdateCart(data) {
	const token = readLocalStorage("BEARER");

	const response = await axios.put(`${baseRoutes.dim}/v1/Carts`, data, {
		headers: {
			Authorization: token,
			"Content-Type": "application/json",
		},
	});
	return response.data;
}

export async function deleteCart(cartId) {
	const token = readLocalStorage("BEARER");

	const response = await axios.delete(`${baseRoutes.dim}/v1/Carts/${cartId}`, {
		headers: {
			Authorization: token,
			"Content-Type": "application/json",
		},
	});
	return response.data;
}

export async function getCart(cartId) {
	const token = readLocalStorage("BEARER");

	const response = await axios.get(`${baseRoutes.dim}/v1/Carts/${cartId}`, {
		headers: {
			Authorization: token,
			"Content-Type": "application/json",
		},
	});
	return response.data;
}

export async function getCartsByTenant() {
	const token = readLocalStorage("BEARER");

	const response = await axios.get(`${baseRoutes.dim}/v1/Carts/GetCartsByTenant`, {
		headers: {
			Authorization: token,
			"Content-Type": "application/json",
		},
	});
	return response.data;
}
