import { Button, Grid, Header } from "semantic-ui-react";
import { Fragment, useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { baseRoutes } from "@/api/base-routes";

import AlphaNumeric from "@/views/dimensioning/capture-forms/alpha-numeric";
import { ReactComponent as AnyOrientation } from "@/assets/images/any-orientation.svg";
import Bool from "@/views/dimensioning/capture-forms/bool";
import { ReactComponent as Comment } from "@/assets/images/comment-image.svg";
import Date from "@/views/dimensioning/capture-forms/date";
import { ReactComponent as DateImage } from "@/assets/images/date-image.svg";
import { ReactComponent as Description } from "@/assets/images/description-image.svg";
import Dimensions from "@/views/dimensioning/capture-forms/dimensions";
import DimensionsImage from "@/assets/images/dimensions-example.png";
import FailedRetry from "@/components/failed-retry";
import { ReactComponent as ItemNumberImage } from "@/assets/images/item-number-image.svg";
import Loading from "@/components/loading";
import { ReactComponent as Manufacturer } from "@/assets/images/manufacturer-image.svg";
import { ReactComponent as Name } from "@/assets/images/name-image.svg";
import Nesting from "@/views/dimensioning/capture-forms/nesting";
import NestingImage from "@/assets/images/nesting-example.png";
import { ReactComponent as NumberImage } from "@/assets/images/number-image.svg";
import Numeric from "@/views/dimensioning/capture-forms/number";
import { ReactComponent as OnItsBack } from "@/assets/images/on-its-back.svg";
import { ReactComponent as OnItsSide } from "@/assets/images/on-its-side.svg";
import Orientation from "@/views/dimensioning/capture-forms/orientation";
import PacksizeSelect from "@/views/dimensioning/capture-forms/select";
import ProductIdentifier from "@/assets/images/upc-example.png";
import { ReactComponent as Quantity } from "@/assets/images/quantity-image.svg";
import ScrollMenu from "@/components/scroll-menu/scroll-menu";
import { ReactComponent as StandingUp } from "@/assets/images/standing-up.svg";
import { ReactComponent as Supplier } from "@/assets/images/supplier-image.svg";
import { ReactComponent as TextImage } from "@/assets/images/text-image.svg";
import VoidFill from "@/views/dimensioning/capture-forms/void-fill";
import { ReactComponent as VoidFillImage } from "@/assets/images/cavity-fill-example.svg";
import Weight from "@/views/dimensioning/capture-forms/weight";
import { ReactComponent as WeightImage } from "@/assets/images/weight-illustration.svg";
import { readLocalStorage } from "@/api/local-storage";
import useAxios from "@/api/useAxios";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";

const useStyles = createUseStyles((theme) => ({
	leftColumn: {
		backgroundColor: "white",
	},
	rightColumn: {
		backgroundColor: theme.light,
		padding: "1.5em !important",
		paddingRight: "2.3em !important",
	},
	header: {
		padding: "1em !important",
		paddingTop: "3em !important",
	},
	subHeader: {
		paddingTop: "1em !important",
	},
	grid: {
		height: "calc(100vh - 48px)",
		minHeight: "550px",
	},
	row: {
		paddingBottom: "0 !important",
		height: "calc(100vh - 48px - 90px)", //Top nav and wizard bar
	},
	formEntry: {
		borderTop: "2px !important",
		marginTop: "0px !important",
		paddingTop: "24px !important",
	},
	wizardForm: {
		padding: "20px",
	},
	footer: {
		position: "fixed",
		bottom: "0",
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "flex-start",
		alignItems: "center",
		alignSelf: "auto",
		paddingLeft: "1.5rem",
		paddingBottom: "1.5rem",
		zIndex: 99,
	},
	image: {
		height: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	orientation: {
		display: "flex",
		justifyContent: "space-around",
		width: "100%",
		height: "50%",
		maxHeight: "225px",
	},
	orientationItem: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		alignItems: "center",
	},
}));

const CollectWizard = () => {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const navigate = useNavigate();

	const token = readLocalStorage("BEARER");
	const tenantId = "00000000-0000-0000-0000-000000000000";
	const { getWithUrl } = useAxios(`${baseRoutes.dim}/v1/Capture`, token);
	const AttributesApi = useAxios(`${baseRoutes.dim}/v1/CustomAttribute`, token);
	const DimensionApi = useAxios(`${baseRoutes.dim}/v1/Product`, token);

	const [loading, setLoading] = useState(true);
	const [failed, setFailed] = useState(false);
	const [product, setProduct] = useState({ attributes: {} });
	const [steps, setSteps] = useState({});
	const [currentStep, setCurrentStep] = useState({});
	const [capture, setCapture] = useState({});

	const load = () => {
		setFailed(false);
		setLoading(true);
		setCapture({});
		AttributesApi.getWithUrl(
			`byTenant/${tenantId}`,
			(data) => {
				setSteps([...steps, ...data.map((d) => ({ id: d.id, ...d.customStep }))]);
			},
			() => {},
		);
		getWithUrl(
			`byTenant/${tenantId}`,
			(data) => {
				if (!data.length) {
					//TODO: Navigate to configure
				}
				setCurrentStep(data[0].attributeCaptureSteps[0]);
				setCapture(data[0]);
				setLoading(false);
			},
			(err) => {
				console.error(err);
				setLoading(false);
				setFailed(true);
			},
		);
	};

	useEffect(load, []);

	const getFormComponent = (step) => {
		var set;

		switch (step.type) {
			case "AlphaNumeric":
				set = (text) => {
					if (step.name === "ProductIdentifier") {
						product.ProductIdentifier = text;
						product[capture.productIdentifierType] = text;
					} else if (step.name === "GTIN") product.GTIN = text;
					else if (step.name === "UPC") product.UPC = text;
					else if (step.name === "EAN") product.EAN = text;
					else if (step.name === "MPN") product.MPN = text;
					else if (step.name === "ISBN") product.ISBN = text;
					else if (step.name === "SKU") {
						product.SKU = text;
						product.IsCustomerSpecificIdentifier = true;
					} else if (step.name === "ItemNumber") {
						product.ItemNumber = text;
						product.IsCustomerSpecificIdentifier = true;
					} else if (step.name === "Name") product.Name = text;
					else if (step.name === "Description") product.Description = text;
					else if (step.name === "Manufacturer") product.Manufacturer = text;
					else if (step.name === "Comment") product.Comment = text;
					else {
						product.attributes[step.name] = text;
					}
					setProduct({ ...product });
				};
				return (
					<AlphaNumeric
						name={step.name}
						prompt={step.prompt}
						defaultPrompt={intl.formatMessage({ id: "Enter Text" })}
						callback={set}
					/>
				);
			case "Date":
				return <Date prompt={step.prompt} />;
			case "Dimensions":
				set = (state) => {
					//TODO: Convert unit
					setProduct({
						...product,
						lengthInMillimeter: state.length,
						widthInMillimeter: state.width,
						heightInMillimeter: state.height,
					});
				};
				return (
					<Dimensions
						prompt={step.prompt}
						defaultPrompt={intl.formatMessage({ id: "Product Dimensions" })}
						callback={set}
					/>
				);
			case "Nesting":
				return <Nesting prompt={step.prompt} />;
			case "Numeric":
				set = (text) => {
					if (step.name === "Quantity") {
						product.Quantity = text;
					} else if (step.name === "ItemNumber") {
						product.ItemNumber = text;
						product.IsCustomerSpecificIdentifier = true;
					} else {
						product.attributes[step.name] = text;
					}
					setProduct({ ...product });
				};
				return <Numeric prompt={step.prompt} defaultPrompt={intl.formatMessage({ id: "Number" })} callback={set} />;
			case "Orientation":
				return <Orientation prompt={step.prompt} />;
			case "SKU":
				return (
					<AlphaNumeric
						name={step.name}
						prompt={step.prompt}
						defaultPrompt={intl.formatMessage({ id: "Enter Product SKU" })}
					/>
				);
			case "Select":
				return <PacksizeSelect prompt={step.prompt} />;
			case "True/False":
				return <Bool prompt={step.prompt} />;
			case "VoidFill":
				return <VoidFill prompt={step.prompt} />;
			case "Weight":
				return <Weight prompt={step.prompt} defaultPrompt={intl.formatMessage({ id: "Weight" })} />;
			case "YesNo":
				return <Bool yesno prompt={step.prompt} />;

			default:
				return <span>{step.name}</span>;
		}
	};

	const getImage = (step) => {
		switch (step.type) {
			case "AlphaNumeric":
				switch (step.name) {
					case "ProductIdentifier":
						return <img src={ProductIdentifier} />;
					case "GTIN":
						return <img src={ProductIdentifier} />;
					case "UPC":
						return <img src={ProductIdentifier} />;
					case "EAN":
						return <img src={ProductIdentifier} />;
					case "MPN":
						return <img src={ProductIdentifier} />;
					case "ISBN":
						return <img src={ProductIdentifier} />;
					case "SKU":
						return <img src={ProductIdentifier} />;
					case "ItemNumber":
						return <img src={ProductIdentifier} />;
					case "Name":
						return <Name />;
					case "Description":
						return <Description />;
					case "Manufacturer":
						return <Manufacturer />;
					case "Comment":
						return <Comment />;
					case "Supplier":
						return <Supplier />;
					default:
						return <TextImage />;
				}
			case "Date":
				return <DateImage />;
			case "Dimensions":
				return <img src={DimensionsImage} />;
			case "Nesting":
				return <img src={NestingImage} />;
			case "Numeric":
				if (step.name === "Quantity") return <Quantity />;
				else if (step.name === "ItemNumber") return <ItemNumberImage />;
				return <NumberImage />;
			case "Orientation":
				return (
					<div className={classes.orientation}>
						<div className={classes.orientationItem}>
							<StandingUp />
							<span>{intl.formatMessage({ id: "Standing Up" })}</span>
						</div>
						<div className={classes.orientationItem}>
							<OnItsSide />
							<span>{intl.formatMessage({ id: "On Its Side" })}</span>
						</div>
						<div className={classes.orientationItem}>
							<OnItsBack />
							<span>{intl.formatMessage({ id: "On Its Back" })}</span>
						</div>
						<div className={classes.orientationItem}>
							<AnyOrientation />
							<span>{intl.formatMessage({ id: "Any Orientation" })}</span>
						</div>
					</div>
				);
			case "SKU":
				return (
					<AlphaNumeric
						name={step.name}
						prompt={step.prompt}
						defaultPrompt={intl.formatMessage({ id: "Enter Product SKU" })}
					/>
				);
			case "Select":
				return <PacksizeSelect prompt={step.prompt} />;
			case "True/False":
				return <Bool prompt={step.prompt} />;
			case "VoidFill":
				return <VoidFillImage />;
			case "Weight":
				return <WeightImage />;
			case "Yes/No":
				return <Bool yesno prompt={step.prompt} />;

			default:
				return <span>{step.name}</span>;
		}
	};

	const wizardSteps = capture?.attributeCaptureSteps?.map((a) => ({
		key: a.name,
		text: a.name,
		ignoreIntl: a.options.includes("Custom"),
	}));
	const onWizardMove = (active) => {
		const current = capture.attributeCaptureSteps.find((i) => i.name === active.key);
		setCurrentStep(current);
	};
	return (
		<Fragment>
			{loading ? (
				<Loading />
			) : failed || !capture?.attributeCaptureSteps ? (
				<FailedRetry retry={load} />
			) : (
				<Fragment>
					<Grid stackable className={classes.grid}>
						<Grid.Row columns={2} className={classes.row}>
							<Grid.Column width={3} className={classes.leftColumn}>
								<Header size="large" className={classes.header}>
									{intl.formatMessage({ id: "Data Capture Wizard" })}
									<Header.Subheader className={classes.subHeader}>
										{/*{false && JSON.stringify(currentStep)}*/}
										{currentStep.name === "ProductIdentifier" &&
											intl.formatMessage({
												id: `Scan the Product Identifier (${capture["productIdentifierType"]}) to get started.`,
											})}
										{currentStep.name === "VoidFill" &&
											intl.formatMessage({
												id: "NOTE Only cubic areas can be measured No curved or sphere shapes can be measured See examples to the right",
											})}
									</Header.Subheader>
								</Header>
								<div className={classes.wizardForm}>{getFormComponent(currentStep)}</div>
								{/*	{false && (
									<div style={{ overflowWrap: "anywhere" }}>
										{JSON.stringify(capture.attributeCaptureSteps.map((a) => a.name))}
									</div>
								)}*/}
								<div className={classes.footer}>
									<Button
										primary
										onClick={() => {
											DimensionApi.addWithUrl(
												tenantId,
												product,
												() => {
													navigate(0);
												},
												(err) => {
													console.error(err);
												},
											);
										}}
									>
										{intl.formatMessage({ id: "Save" })}
									</Button>
									<Button
										onClick={() => {
											navigate("/dimensioning/collect");
										}}
									>
										{intl.formatMessage({ id: "Use Form" })}
									</Button>
								</div>
							</Grid.Column>
							<Grid.Column width={13} className={classes.rightColumn}>
								<div className={classes.image}>{getImage(currentStep)}</div>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row columns={2}>
							<Grid.Column width={3}></Grid.Column>
							<Grid.Column width={13}>
								<ScrollMenu
									scrollVisible
									selected={currentStep.name}
									list={wizardSteps ?? []}
									onActiveSet={onWizardMove}
								/>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Fragment>
			)}
		</Fragment>
	);
};

export default CollectWizard;
