import { FormattedMessage } from "react-intl";
import { createUseStyles } from "react-jss";
import { ReactComponent as NoDataImage } from "@/views/reporting/img/no-site.svg";

const useStyles = createUseStyles(() => ({
	noSchedules: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		flex: "1",
		backgroundColor: "white",
		padding: "20px",
		borderRadius: "8px",
		height: "100%",
		gap: "16px",
		width: "98%",
		marginBottom: "22px",
	},
	largeText: {
		fontFamily: "Stolzl-Medium",
		color: "#353430",
		fontSize: "24px",
	},
}));

function NoSchedules({ showHeaderImage = true }) {
	const classes = useStyles();

	return (
		<div className={classes.noSchedules}>
			{showHeaderImage && <NoDataImage></NoDataImage>}
			<span className={classes.largeText}>
				<FormattedMessage id="Missing Planned Production Schedule" />
			</span>
			<span>
				<FormattedMessage
					id="{addaproductionschedule} or select a different date range"
					values={{
						addaproductionschedule: (
							<a href="../configuration/planned-production" target="_blank" rel="noopener noreferrer">
								<FormattedMessage id="Add a production schedule" />
							</a>
						),
					}}
				/>
			</span>
		</div>
	);
}

export default NoSchedules;
