import axios from "axios";
import { readLocalStorage } from "./local-storage";
import { baseRoutes } from "./base-routes";

export async function createCustomAttribute(data) {
	const token = readLocalStorage("BEARER");

	const response = await axios.post(`${baseRoutes.dim}/v1/customattributes`, data, {
		headers: {
			Authorization: token,
			"Content-Type": "application/json",
		},
	});
	return response.data;
}
