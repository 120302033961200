import { createUseStyles } from "react-jss";
import clsx from "clsx";

const useStyles = createUseStyles((theme) => ({
	switch: {
		backgroundColor: theme.colors.accent,
		width: 40,
		height: 24,
		display: "inline-block",
		borderRadius: 6,

		"&:after": {
			content: "''",
			display: "block",
			margin: 1,
			width: 22,
			height: 22,
			borderRadius: 6,
			backgroundColor: theme.colors.white,
		},

		"&$active": {
			backgroundColor: theme.colors.primary,

			"&:after": {
				marginLeft: "auto",
			},
		},
	},
	label: {
		display: "flex",
		alignItems: "center",
		columnGap: 25,
		fontFamily: theme.fonts.small,
		color: theme.colors.text,
		cursor: "pointer",
		width: "fit-content",
	},

	checkbox: {
		display: "none",
	},

	active: {},
}));

const ToggleButton = ({ children, checked, onChange, name, ...extra }) => {
	const classes = useStyles();
	return (
		<label className={classes.label} {...extra}>
			<span className={clsx(classes.switch, checked && classes.active)}></span>
			<input onChange={onChange} name={name} type="checkbox" className={classes.checkbox} defaultChecked={checked} />
			{children}
		</label>
	);
};

export default ToggleButton;
