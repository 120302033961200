import { createUseStyles, useTheme } from "react-jss";
import { FormattedMessage } from "react-intl";
import { useMeasure } from "react-use";
import DatePickerDropdown from "@/views/reporting/date-picker-dropdown";
import MachineSelectorDropdown from "@/views/reporting/machine-selector-dropdown";
import { ReactComponent as BackArrow } from "@/views/reporting/img/back-arrow.svg";
import { isFeatureFlagEnabled } from "@/utils/auth";
import PlannedProductionToggle from "./planned-production-toggle";

const useStyles = createUseStyles({
	backArrow: {
		position: "relative",
		top: "6px",
	},
	backButton: {
		cursor: "pointer",
	},
	container: {
		display: "grid",
		gridTemplateColumns: "repeat(4, 25%)",
		gridTemplateRows: "124px",
		alignItems: "center",
		"& > div:not(:first-child)": {
			paddingLeft: "18px",
		},
	},
	title: {
		gridColumn: "span 2",
		"& > h1": {
			margin: 0,
		},
	},
	toggle: {
		gridColumn: "span 2",
		marginBottom: "24px",
		paddingLeft: "00px !important",
	},
	dropdownHeading: {
		display: "flex",
		alignItems: "center",
	},
});
function ReportingHeader({
	currentUser,
	header,
	backMessage,
	dateOptions,
	setDateOptions,
	internalNavigation,
	setInternalNavigation,
	machines,
	setMachines,
	usePlannedProduction = false,
	setUsePlannedProduction,
}) {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const [ref, { width }] = useMeasure();
	const featureFlagBucket = isFeatureFlagEnabled(currentUser, "pack-net-reporting-errors-availabilty-bucket");

	return (
		<div className={classes.container}>
			<div className={classes.title}>
				{internalNavigation === "" ? (
					<h1>
						<FormattedMessage id={header} />
					</h1>
				) : (
					<div className={classes.backButton} onClick={() => setInternalNavigation("")}>
						<BackArrow className={classes.backArrow} />
						{backMessage ? <FormattedMessage id={backMessage} /> : <FormattedMessage id="Back" />}
					</div>
				)}
			</div>
			<div ref={ref}>
				<div>
					{featureFlagBucket && (
						<MachineSelectorDropdown
							dateOptions={dateOptions}
							machines={machines}
							setMachines={setMachines}
							currentUser={currentUser}
							dropdownWidth={width}
						/>
					)}
				</div>
			</div>
			<div ref={ref}>
				<div>
					<DatePickerDropdown dateOptions={dateOptions} setDateOptions={setDateOptions} datePickerWidth={width} />
				</div>
			</div>
			{usePlannedProduction && (
				<div className={classes.toggle}>
					<PlannedProductionToggle
						setUsePlannedProduction={setUsePlannedProduction}
						usePlannedProduction={usePlannedProduction}
					></PlannedProductionToggle>
				</div>
			)}
		</div>
	);
}

export default ReportingHeader;
