import { FormattedMessage, useIntl } from "react-intl";
import { Fragment, useContext, useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";

import ConfirmModal from "../../../components/confirm-modal";
import DeleteButton from "@/components/delete-button";
import EditButton from "@/components/edit-button";
import FailedRetry from "@/components/failed-retry";
import ListContext from "@/components/list-context";
import Loading from "@/components/loading";
import { Table } from "semantic-ui-react";
import useOnLoadedEffect from "@/components/use-on-loaded-effect";
import { identityApi } from "@/api/identitySdk.js";

const useStyles = createUseStyles((theme) => ({
	...theme.configurationDialog.listView,
	spacer: {
		width: "32px !important",
	},
}));

const Users = () => {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const [roles, setRoles] = useState([]);
	const [configuredRoles, setConfiguredRoles] = useState([]);
	const { list, setList, setEdit, loading, setLoading, failed, setFailed } = useContext(ListContext);
	const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
	const [itemToDelete, setItemToDelete] = useState({});

	const load = () => {
		setFailed(false);
		setLoading(true);
		setList([]);

		identityApi.getUsers().then((response) => {
			if (response.success) {
				setList(response.responseData);
				setLoading(false);
			} else {
				setLoading(false);
				setFailed(true);
			}
		});

		identityApi.getRoles().then((response) => {
			if (response.success) {
				setRoles(response.responseData);
				setLoading(false);
			} else {
				setLoading(false);
				setFailed(true);
			}
		});
	};

	const removeItem = async (item) => {
		setItemToDelete(item);
		setIsDeleteModalOpened(true);
	};

	const getConfiguredRoles = (users, roles) => {
		return users.reduce((acc, val) => {
			if (roles.length === 0 || val.assignedRoles.length === 0) return acc;

			const names = val.assignedRoles.map((id) => {
				const role = roles.filter((m) => m.id === id);
				return role.length > 0 ? role[0].name : "";
			});
			acc[val.id] = names;
			return acc;
		}, {});
	};

	useEffect(() => {
		if (list.length === 0) return;

		const r = getConfiguredRoles(list, roles);
		setConfiguredRoles(r);
	}, [list, roles]);

	useOnLoadedEffect(load);

	return (
		<Fragment>
			<ConfirmModal
				open={isDeleteModalOpened}
				onClose={async (response) => {
					if (response) {
						const removeResponse = await identityApi.removeUser(itemToDelete.id);

						if (removeResponse.success) {
							const response = await identityApi.getUsers();

							if (response.success) {
								setList(response.responseData);
							}
						}
					}
					setIsDeleteModalOpened(false);
					setItemToDelete({});
				}}
				titleText={intl.formatMessage(
					{
						id: "Delete User",
					},
					{
						alias: itemToDelete && itemToDelete.alias ? itemToDelete.alias : "",
					},
				)}
				detailText="This change can not be undone"
				confirmText="Delete"
				cancelText="Cancel"
			/>
			{loading ? (
				<Loading />
			) : failed ? (
				<FailedRetry retry={load} />
			) : (
				<Table basic="very" className={classes.table}>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>
								<FormattedMessage id="Username" />
							</Table.HeaderCell>
							<Table.HeaderCell>
								<FormattedMessage id="First Name" />
							</Table.HeaderCell>
							<Table.HeaderCell>
								<FormattedMessage id="Last Name" />
							</Table.HeaderCell>
							<Table.HeaderCell>
								<FormattedMessage id="Roles" />
							</Table.HeaderCell>
							<Table.HeaderCell textAlign="center" collapsing>
								<FormattedMessage id="Edit" />
							</Table.HeaderCell>
							<Table.HeaderCell className={classes.spacer}></Table.HeaderCell>
							<Table.HeaderCell textAlign="right" collapsing>
								<FormattedMessage id="Delete" />
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body>
						{list.map((item) => (
							<Table.Row key={item.id}>
								<Table.Cell>{item.userName}</Table.Cell>
								<Table.Cell>{item.firstName}</Table.Cell>
								<Table.Cell>{item.lastName}</Table.Cell>
								<Table.Cell>{configuredRoles[item.id] ? configuredRoles[item.id].join(", ") : ""}</Table.Cell>
								<Table.Cell textAlign="center" collapsing className={classes.actionButtons}>
									<EditButton onClick={() => setEdit(item)} />
								</Table.Cell>
								<Table.Cell className={classes.spacer}></Table.Cell>
								<Table.Cell textAlign="right" collapsing className={classes.actionButtons}>
									<DeleteButton onClick={() => removeItem(item)} />
								</Table.Cell>
							</Table.Row>
						))}
					</Table.Body>
				</Table>
			)}
		</Fragment>
	);
};

export default Users;
