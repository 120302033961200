import { Button, Modal, Table } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import { Fragment, useContext, useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { UserContext } from "@/components/user-context";
import { baseRoutes } from "@/api/base-routes.js";

import ConfirmModal from "../../../components/confirm-modal";
import DeleteButton from "@/components/delete-button";
import DiffView from "@/components/diff-view";
import EditButton from "@/components/edit-button";
import FailedRetry from "@/components/failed-retry";
import ListContext from "@/components/list-context";
import Loading from "@/components/loading";
import { readLocalStorage } from "@/api/local-storage";
import useAxios from "@/api/useAxios";

const useStyles = createUseStyles((theme) => ({
	...theme.configurationDialog.listView,
	spacer: {
		width: "32px !important",
	},
}));

const CartonPropertyGroups = () => {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const { currentUser } = useContext(UserContext);
	const useAssignPgToCpgs = currentUser["ff-cpg-allow-pg-assignment"];
	const token = readLocalStorage("BEARER");
	const { list, setList, setEdit, loading, setLoading, failed, setFailed } = useContext(ListContext);
	const { get, remove } = useAxios(`${baseRoutes.cartonPropertyGroup}/v1/cartonPropertyGroups`, token);
	const productionGroupsApi = useAxios(`${baseRoutes.productionGroup}/v1/ProductionGroups`, token);
	const [assignedProductionGroups, setAssignedProductionGroups] = useState([]);
	const [productionGroups, setProductionGroups] = useState([]);
	const configurationAuditApi = useAxios(`${baseRoutes.audit}/v1/ConfigurationAudit`, token);
	const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
	const [isDiffModalOpened, setIsDiffModalOpened] = useState(false);
	const [itemToDelete, setItemToDelete] = useState({});
	const [historyData, setHistoryData] = useState({});
	const [historyItem, setHistoryItem] = useState({});

	const getAssignedProductionGroups = (cpgs, productionGroups) => {
		return cpgs.reduce((acc, val) => {
			if (
				productionGroups.length === 0 ||
				val.assignedToProductionGroups === undefined ||
				val.assignedToProductionGroups.length === 0
			)
				return acc;

			const names = val.assignedToProductionGroups
				.map((id) => {
					const pg = productionGroups.filter((m) => m.id === id);
					return pg.length > 0 ? pg[0].alias : "";
				})
				.filter((v) => v !== "");

			names.sort();
			acc[val.id] = names.join(", ");
			return acc;
		}, {});
	};

	useEffect(() => {
		setAssignedProductionGroups(getAssignedProductionGroups(list, productionGroups));
	}, [list, productionGroups]);

	const load = () => {
		setFailed(false);
		setLoading(true);
		setList([]);
		productionGroupsApi.get(
			(data) => setProductionGroups(data),
			() => {
				setLoading(false);
				setFailed(true);
			},
		);
		get(
			(data) => {
				setList(data);
				setLoading(false);
			},
			() => {
				setLoading(false);
				setFailed(true);
			},
		);
	};

	useEffect(load, []);

	const removeItem = async (item) => {
		setItemToDelete(item);
		setIsDeleteModalOpened(true);
	};

	return (
		<Fragment>
			{loading ? (
				<Loading />
			) : failed ? (
				<FailedRetry retry={load} />
			) : (
				<>
					<Modal
						size="fullscreen"
						centered={true}
						closeOnEscape={true}
						closeOnDimmerClick={true}
						className={classes.modal}
						open={isDiffModalOpened}
						onClose={async () => {
							setIsDiffModalOpened(false);
							setHistoryItem({});
						}}
					>
						<Modal.Header className={classes.title}>
							<FormattedMessage id="Carton Property Group History:" /> {historyItem?.alias ?? ""}
						</Modal.Header>
						<Modal.Content className={classes.content}>
							<div className={classes.diff}>
								<DiffView data={historyData} />
							</div>
						</Modal.Content>
					</Modal>
					<ConfirmModal
						open={isDeleteModalOpened}
						onClose={async (response) => {
							if (response) {
								remove(itemToDelete.id, setList);
							}
							setIsDeleteModalOpened(false);
							setItemToDelete({});
						}}
						titleText={intl.formatMessage(
							{
								id: "Delete carton property group",
							},
							{
								alias: itemToDelete && itemToDelete.alias ? itemToDelete.alias : "",
							},
						)}
						detailText="This change can not be undone"
						confirmText="Delete"
						cancelText="Cancel"
					/>
					{!useAssignPgToCpgs ? (
						<Table basic="very" className={classes.table}>
							<Table.Header>
								<Table.Row className={classes.headerRow}>
									<Table.HeaderCell>
										<FormattedMessage id="Name" />
									</Table.HeaderCell>
									<Table.HeaderCell className={classes.editButton} textAlign="center" collapsing>
										<FormattedMessage id="History" />
									</Table.HeaderCell>
									<Table.HeaderCell className={classes.spacer}></Table.HeaderCell>
									<Table.HeaderCell className={classes.editButton} textAlign="center" collapsing>
										<FormattedMessage id="Edit" />
									</Table.HeaderCell>
									<Table.HeaderCell className={classes.spacer}></Table.HeaderCell>
									<Table.HeaderCell textAlign="right" className={classes.deleteButton} collapsing>
										<FormattedMessage id="Delete" />
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>

							<Table.Body>
								{list.map((item) => (
									<Table.Row key={item.id}>
										<Table.Cell>{item.alias}</Table.Cell>
										<Table.Cell textAlign="center" collapsing className={classes.actionButtons}>
											<Button
												basic
												icon="history"
												className={classes.actionButtons}
												onClick={() => {
													configurationAuditApi.getWithUrl(`CartonPropertyGroup/${item.id}`, (data) => {
														setIsDiffModalOpened(true);
														setHistoryData(data);
														setHistoryItem(item);
													});
												}}
												data-testid="carton-property-group-history-button"
											/>
										</Table.Cell>
										<Table.Cell className={classes.spacer}></Table.Cell>
										<Table.Cell textAlign="center" collapsing className={classes.actionButtons}>
											<EditButton onClick={() => setEdit(item)} data-testid="carton-property-group-edit-button" />
										</Table.Cell>
										<Table.Cell className={classes.spacer}></Table.Cell>
										<Table.Cell textAlign="right" collapsing className={classes.actionButtons}>
											<DeleteButton
												onClick={() => removeItem(item)}
												data-testid="carton-property-group-delete-button"
											/>
										</Table.Cell>
									</Table.Row>
								))}
							</Table.Body>
						</Table>
					) : (
						<Table basic="very" className={classes.table}>
							<Table.Header>
								<Table.Row className={classes.headerRow}>
									<Table.HeaderCell>
										<FormattedMessage id="Name" />
									</Table.HeaderCell>
									<Table.HeaderCell>
										<FormattedMessage id="Production Groups" />
									</Table.HeaderCell>
									<Table.HeaderCell>
										<FormattedMessage id="Ratio" />
									</Table.HeaderCell>
									<Table.HeaderCell className={classes.editButton} textAlign="center" collapsing>
										<FormattedMessage id="History" />
									</Table.HeaderCell>
									<Table.HeaderCell className={classes.spacer}></Table.HeaderCell>
									<Table.HeaderCell className={classes.editButton} textAlign="center" collapsing>
										<FormattedMessage id="Edit" />
									</Table.HeaderCell>
									<Table.HeaderCell className={classes.spacer}></Table.HeaderCell>
									<Table.HeaderCell textAlign="right" className={classes.deleteButton} collapsing>
										<FormattedMessage id="Delete" />
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>

							<Table.Body>
								{list.map((item) => (
									<Table.Row key={item.id}>
										<Table.Cell>{item.alias}</Table.Cell>
										<Table.Cell>{assignedProductionGroups[item.id]}</Table.Cell>
										<Table.Cell>{item.ratio}</Table.Cell>
										<Table.Cell textAlign="center" collapsing className={classes.actionButtons}>
											<Button
												basic
												icon="history"
												className={classes.actionButtons}
												onClick={() => {
													configurationAuditApi.getWithUrl(`CartonPropertyGroup/${item.id}`, (data) => {
														setIsDiffModalOpened(true);
														setHistoryData(data);
														setHistoryItem(item);
													});
												}}
												data-cy="carton-property-group-history-button"
											/>
										</Table.Cell>
										<Table.Cell className={classes.spacer}></Table.Cell>
										<Table.Cell textAlign="center" collapsing className={classes.actionButtons}>
											<EditButton onClick={() => setEdit(item)} data-cy="carton-property-group-edit-button" />
										</Table.Cell>
										<Table.Cell className={classes.spacer}></Table.Cell>
										<Table.Cell textAlign="right" collapsing className={classes.actionButtons}>
											<DeleteButton onClick={() => removeItem(item)} data-cy="carton-property-group-delete-button" />
										</Table.Cell>
									</Table.Row>
								))}
							</Table.Body>
						</Table>
					)}
				</>
			)}
		</Fragment>
	);
};

export default CartonPropertyGroups;
