import { useLocalStorage } from "@/views/reporting/custom-hooks/local-storage-hook";
import { FormattedMessage } from "react-intl";
import ToggleButton from "../../components/toggle-button";
import { useEffect, useState } from "react";

function PlannedProductionToggle({ setUsePlannedProduction }) {
	const SET_USE_PLANNED_PRODUCTION = "SET_USE_PLANNED_PRODUCTION";

	const [usePlannedProductionLocalStorage, setUsePlannedProductionLocalStorage] =
		useLocalStorage(SET_USE_PLANNED_PRODUCTION);

	const initialToggleState =
		usePlannedProductionLocalStorage !== "" ? JSON.parse(usePlannedProductionLocalStorage) : true;

	const [toggleIsEnabled, setToggleIsEnabled] = useState(initialToggleState);

	useEffect(() => {
		setUsePlannedProduction(toggleIsEnabled);
	}, [toggleIsEnabled, setUsePlannedProduction]);

	function toggleUsePlannedProduction() {
		const updatedValue = !toggleIsEnabled;
		setToggleIsEnabled(updatedValue);
		setUsePlannedProductionLocalStorage(updatedValue.toString());
	}

	return (
		<ToggleButton
			checked={toggleIsEnabled}
			onChange={toggleUsePlannedProduction}
			data-cy="toggle-enable-scan-to-trigger"
			name={"toggle-planned-production"}
		>
			<FormattedMessage id="Show data using your Planned Production Schedule" />
		</ToggleButton>
	);
}

export default PlannedProductionToggle;
