import { createContext, useContext, useEffect, useState } from "react";
import { PublicSettingsContext } from "@/contexts/public-settings-context.jsx";
import { initialize } from "launchdarkly-js-client-sdk";
const featureFlagContext = createContext({});

// This context should eventually subscribe to flag updates live and show them real time instead of on load.
export const FeatureFlagProvider = ({ children }) => {
	const publicSettings = useContext(PublicSettingsContext);
	const [featureFlagIdentity, setFeatureFlagIdentity] = useState(null);
	const [flags, setFlags] = useState({});

	useEffect(() => {
		if (publicSettings?.launchDarklyKey === "") return;

		let context = {
			kind: "user",
			anonymous: true,
		};

		if (featureFlagIdentity?.id) {
			context = {
				kind: "user",
				anonymous: false,
				key: `user-${featureFlagIdentity.id}`,
			};
		}

		const LDClient = initialize(publicSettings.launchDarklyKey, context);

		LDClient.waitForInitialization(3)
			.then(() => {
				setFlags(LDClient.allFlags());
			})
			.catch((err) => {
				console.error("LaunchDarkly initialization error or timeout: ", err);
			});
	}, [featureFlagIdentity, publicSettings]);

	return (
		<featureFlagContext.Provider value={{ flags, setFeatureFlagIdentity }}>{children}</featureFlagContext.Provider>
	);
};
export const useFeatureFlags = () => useContext(featureFlagContext);
