import packNetAxios from "../lib/api-client.js";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { baseRoutes } from "@/api/base-routes.js";

export const scanImport = async (machineGroup, barcode) => {
	await packNetAxios.post(`${baseRoutes.import}/v1/scan/${machineGroup}`, barcode, {
		headers: {
			"Content-Type": "application/json",
		},
	});
};

export const importDimData = (data, scope) =>
	packNetAxios.post(baseRoutes.import + "/v1/importDimData", data, { params: { scope } });

export async function getDefaultImportPipeline() {
	const response = await packNetAxios.get(baseRoutes.import + "/v1/ImportExport/default");
	return response.data;
}

export async function getImportPipelineExportData(scope) {
	const response = await packNetAxios.get(baseRoutes.import + "/v1/ImportExport", { params: { scope } });
	return response.data;
}

export async function upgradeLegacyPipeline(data) {
	const response = await packNetAxios.post(baseRoutes.import + "/v1/UpgradeLegacyPipeline", data);
	return response.data;
}

export async function postImportPipeline(data, dryRun) {
	const response = await packNetAxios.post(baseRoutes.import + "/v1/ImportExport", data, { params: { dryRun } });
	return response.data;
}

export const postImportData = async (data) => {
	const response = await packNetAxios.post(baseRoutes.import + "/v1/importData", data);
	return response.data;
};

export const downloadImportClient = async () => {
	const response = await packNetAxios.get(baseRoutes.import + "/v1/importClient");

	const link = document.createElement("a");
	link.href = response.data.downloadUri;
	try {
		document.body.appendChild(link);
		link.click();
	} finally {
		document.body.removeChild(link);
	}
};

const decorateSolutionIds = (solutions) => {
	return solutions.map((s) => ({
		...s,
		errors: [],
		cartons: s.cartons?.map((c) => ({
			...c,
			id: crypto.randomUUID(),
			errors: [],
			prints: c.prints?.map((p) => ({ ...p, id: crypto.randomUUID(), errors: [] })),
			labels: c.labels?.map((l) => ({
				...l,
				id: crypto.randomUUID(),
				errors: [],
			})),
		})),
		labels: s.labels?.map((l) => ({
			...l,
			id: crypto.randomUUID(),
			errors: [],
		})),
	}));
};

export const getPackagingSolutions = () => {
	return packNetAxios.get(baseRoutes.import + "/v1/entities/packagingsolutions").then((result) => {
		const data = decorateSolutionIds(result.data);
		data.sort((a, b) => a.alias.localeCompare(b.alias));

		return data;
	});
};

export const getPackagingSolutionById = (solutionId) => {
	return packNetAxios.get(baseRoutes.import + `/v1/entities/packagingsolutions/${solutionId}`).then((result) => {
		return decorateSolutionIds([result.data])[0];
	});
};

export const usePackagingSolutions = ({ queryConfig = {} } = {}) =>
	useQuery({
		...queryOptions({
			queryKey: ["packaging-solutions"],
			queryFn: getPackagingSolutions,
		}),
		...queryConfig,
	});

export const bulkDeletePackagingSolutions = (ids) => {
	return packNetAxios.delete(baseRoutes.import + "/v1/entities/packagingsolutions/batch", { data: ids });
};

export const putPackagingSolution = async (solution, dryRun) => {
	const response = await packNetAxios.put(
		baseRoutes.import + `/v1/entities/packagingsolutions/${solution.id || solution.Id}`,
		solution,
		{
			params: { dryRun },
		},
	);
	return decorateSolutionIds([response.data])[0];
};
