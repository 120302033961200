import { useIntl } from "react-intl";
import React, { useEffect, useMemo, useState } from "react";
import { ReactComponent as CheckIcon } from "@/views/reporting/img/Check-Icon.svg";
import ReportingTable from "@/views/reporting/reporting-table";
import { ReactComponent as XIcon } from "@/views/reporting/img/X-Icon.svg";
import { getRawDataByPlannedProductionSchedulesForSite } from "@/views/reporting/services/API/Reports/raw-data-api";
import { getPlannedProductionDataForSite } from "@/views/reporting/services/API/Reports/planned-production-api";
import { ensureSiteName, incrementDateRange, midnightDate, toLocalFormat } from "@/views/reporting/functions/index";
import ExperienceContainer from "../layout/experience-container";
import Row from "../layout/row";
import DashboardCard from "../dashboard-card";

import errorBoundary from "@/components/error-boundary";
import { isFeatureFlagEnabled } from "@/utils/auth";
import { getOriginalUOM, getTargetUOM } from "../functions/unit-of-measure";

function RawData({ currentUser }) {
	const intl = useIntl();
	const selectedSites = useMemo(() => [currentUser.CustomerId, currentUser.tid], [currentUser]);
	const [internalNavigation, setInternalNavigation] = useState("");
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [unitLabel, setUnitLabel] = useState("sq in");
	const [singularUnitLabel, setSingularUnitLabel] = useState("in");
	const [machines, setMachines] = useState(() => {
		const machinesSaved = localStorage.getItem("MACHINES");
		return machinesSaved !== null && machinesSaved !== "undefined" ? JSON.parse(machinesSaved) : [];
	});
	const enableOperatorUserName = isFeatureFlagEnabled(currentUser, "pack-net-reporting-operator-user-name");
	const reportingRawDataAreaLargeUom = isFeatureFlagEnabled(currentUser, "reporting-raw-data-area-large-uom");

	const [dateOptions, setDateOptions] = useState({
		start: midnightDate(new Date()),
		end: midnightDate(new Date()),
	});

	const featureFlagBucket = isFeatureFlagEnabled(currentUser, "pack-net-reporting-errors-availabilty-bucket");

	let sitesSelection = [];
	if (selectedSites) {
		sitesSelection = selectedSites;
	}

	const isSiteMetric = currentUser.unitofmeasure === "mm";

	const columns = React.useMemo(() => {
		let tableColumns = [
			{
				Header: intl.formatMessage({ id: "Date" }),
				Cell: ({ row }) => {
					return new Date(row.original.utcDateTime.slice(0, -1)).toLocaleDateString();
				},
			},
			{
				Header: intl.formatMessage({ id: "Time" }),
				Cell: ({ row }) => {
					var timeStamp = toLocalFormat(new Date(row.original.utcDateTime.slice(0, -1)));
					const time = timeStamp.split("T")[1];
					let hour = parseInt(time.split(":")[0]);
					const suffix = hour < 12 ? "AM" : "PM";
					hour = hour % 12;
					if (hour === 0) hour = 12;
					return `${hour}:${time.split(":")[1]}:${time.split(":")[2]} ${suffix}`;
				},
			},
			{
				Header: intl.formatMessage({ id: "Article Name" }),
				accessor: "articleName",
				dangerousField: true,
			},
			{
				Header: intl.formatMessage({ id: "Job Title" }),
				accessor: "alias",
				dangerousField: true,
			},
			{
				Header: intl.formatMessage({ id: "Cartons Produced" }),
				accessor: "cartonsProduced",
			},
			{
				Header: intl.formatMessage({ id: "Tiled" }),
				accessor: "tiled",
				Cell: ({ row }) => <>{row.original.tiled === "true" ? <CheckIcon /> : <XIcon />}</>,
			},
			{
				Header: intl.formatMessage({ id: "Packaging Design Id" }),
				accessor: "designId",
			},
			{
				Header: intl.formatMessage({ id: "Design Name" }),
				accessor: "designName",
				dangerousField: true,
			},
			{
				Header: intl.formatMessage({ id: "zFold Name" }),
				accessor: "zFoldName",
				dangerousField: true,
			},
			{
				Header: intl.formatMessage({ id: `zFold Width ${singularUnitLabel}` }),
				accessor: "zFoldWidth",
			},
			{
				Header: intl.formatMessage({ id: "zFold Flute" }),
				accessor: "zFoldFlute",
			},
			{
				Header: intl.formatMessage({ id: "zFold Brand" }),
				accessor: "zFoldBrand",
			},
			{
				Header: intl.formatMessage({ id: `Box Length ${singularUnitLabel}` }),
				accessor: "boxLength",
				Cell: ({ row }) => (
					<>
						{Number(row.original.boxLength).toLocaleString(undefined, {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}
					</>
				),
			},
			{
				Header: intl.formatMessage({ id: `Box Width ${singularUnitLabel}` }),
				accessor: "boxWidth",
				Cell: ({ row }) => (
					<>
						{Number(row.original.boxWidth).toLocaleString(undefined, {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}
					</>
				),
			},
			{
				Header: intl.formatMessage({ id: `Box Height ${singularUnitLabel}` }),
				accessor: "boxHeight",
				Cell: ({ row }) => (
					<>
						{Number(row.original.boxHeight).toLocaleString(undefined, {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}
					</>
				),
			},
			{
				Header: intl.formatMessage({ id: `zFold Used Length ${singularUnitLabel}` }),
				accessor: "zFoldUsedLength",
				Cell: ({ row }) => (
					<>
						{Number(row.original.zFoldUsedLength).toLocaleString(undefined, {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}
					</>
				),
			},
			{
				Header: intl.formatMessage({ id: `zFold Used Width ${singularUnitLabel}` }),
				accessor: "zFoldUsedWidth",
				Cell: ({ row }) => (
					<>
						{Number(row.original.zFoldUsedWidth).toLocaleString(undefined, {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}
					</>
				),
			},
			{
				Header: intl.formatMessage({ id: "Machine" }),
				accessor: "machine",
				dangerousField: true,
			},
			{
				Header: intl.formatMessage({ id: `Total zFold Area ${unitLabel}` }),
				accessor: "totalZFoldArea",
				Cell: ({ row }) => (
					<>
						{Number(row.original.totalZFoldArea).toLocaleString(undefined, {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}
					</>
				),
			},
			{
				Header: intl.formatMessage({ id: `Carton Area ${unitLabel}` }),
				accessor: "cartonArea",
				Cell: ({ row }) => (
					<>
						{Number(row.original.cartonArea).toLocaleString(undefined, {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}
					</>
				),
			},
			{
				Header: intl.formatMessage({ id: `Area Per Box ${unitLabel}` }),
				accessor: "areaPerBox",
				Cell: ({ row }) => (
					<>
						{Number(row.original.areaPerBox).toLocaleString(undefined, {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}
					</>
				),
			},
			{
				Header: intl.formatMessage({ id: `Trim Area ${unitLabel}` }),
				accessor: "trimArea",
				Cell: ({ row }) => (
					<>
						{Number(row.original.trimArea).toLocaleString(undefined, {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}
					</>
				),
			},
			{
				Header: intl.formatMessage({ id: "Trim percentage" }),
				accessor: "trimPercentage",
				Cell: ({ row }) => (
					<>{isNaN(row.original.trimPercentage) ? "0.00%" : parseFloat(row.original.trimPercentage).toFixed(2) + "%"}</>
				),
			},
			{
				Header: intl.formatMessage({ id: "Produced Optimally" }),
				accessor: "producedOptimally",
				Cell: ({ row }) => <>{row.original.producedOptimally === "true" ? <CheckIcon /> : <XIcon />}</>,
			},
			{
				Header: intl.formatMessage({ id: "Flaps Down" }),
				accessor: "flapsDown",
				Cell: ({ row }) => <>{row.original.flapsDown === "true" ? <CheckIcon /> : <XIcon />}</>,
			},
			{
				Header: intl.formatMessage({ id: "Flaps Down Band Used" }),
				accessor: "flapsDownBandUsed",
				Cell: ({ row }) => (
					<>
						{!row.original.flapsDownBandUsed
							? ""
							: Number(row.original.flapsDownBandUsed).toLocaleString(undefined, {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								})}
					</>
				),
			},
			{
				Header: intl.formatMessage({ id: "Cycle Time In Seconds" }),
				accessor: "cycleTimeInSeconds",
				Cell: ({ row }) => <>{row.original.cycleTimeInSeconds}</>,
			},
			{
				Header: intl.formatMessage({ id: "Barcode" }),
				accessor: "barcode",
				Cell: ({ row }) => <>{row.original.barcode}</>,
				dangerousField: true,
			},
			{
				Header: intl.formatMessage({ id: "Succeeded" }),
				accessor: "succeeded",
				Cell: ({ row }) => (
					<>{row.original.succeeded === true || row.original.succeeded === "true" ? <CheckIcon /> : <XIcon />}</>
				),
			},
		];

		if (enableOperatorUserName) {
			tableColumns.push({
				Header: intl.formatMessage({ id: "Operator" }),
				accessor: "operatorUserName",
				dangerousField: true,
			});
		}

		return tableColumns;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [intl, unitLabel, singularUnitLabel]);

	async function getData(sites, dateRange) {
		setIsLoading(true);
		const newDateRange = incrementDateRange(dateRange);
		let rawData = [];
		let plannedProduction = [];
		const featureFlagWIP = false; // isFeatureFlagEnabled(currentUser, "pack-net-reporting-wip");

		if (featureFlagWIP) {
			plannedProduction = await getPlannedProductionDataForSite(sites, newDateRange);
		}

		rawData = await getRawDataByPlannedProductionSchedulesForSite(
			plannedProduction,
			sites,
			machines.filter((m) => m.checked).map((m) => m.id),
			newDateRange,
			reportingRawDataAreaLargeUom,
			isSiteMetric,
		);

		ensureSiteName(rawData, currentUser.CustomerId);
		setData(rawData);
		const [known, originalUOM] = getOriginalUOM(rawData, isSiteMetric);
		const preferredMeasurement = getTargetUOM(known, originalUOM, isSiteMetric);
		if (reportingRawDataAreaLargeUom) {
			setUnitLabel(preferredMeasurement === "squaredMeters" ? "sq m" : "sq ft");
		} else {
			setUnitLabel(preferredMeasurement === "squaredMeters" ? "sq mm" : "sq in");
		}

		setSingularUnitLabel(preferredMeasurement === "squaredMeters" ? "mm" : "in");
		setIsLoading(false);
	}

	useEffect(() => {
		if (dateOptions.id) getData(sitesSelection, dateOptions);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dateOptions, sitesSelection, machines]);

	return (
		<ExperienceContainer
			currentUser={currentUser}
			header="Raw Data"
			backMessage=""
			dateOptions={dateOptions}
			setDateOptions={setDateOptions}
			internalNavigation={internalNavigation}
			setInternalNavigation={setInternalNavigation}
			machines={machines}
			setMachines={setMachines}
			isLoading={isLoading}
			noData={!data.length || (featureFlagBucket && machines.every((m) => !m.checked))}
		>
			<Row bottom>
				<DashboardCard key="Raw Data" showFooter={false} showHeader={false}>
					<ReportingTable
						columns={columns}
						data={data}
						header={null}
						dateOptions={dateOptions}
						filterable={true}
						enableOperatorUserName={enableOperatorUserName}
					/>
				</DashboardCard>
			</Row>
		</ExperienceContainer>
	);
}

// eslint-disable-next-line react-refresh/only-export-components
export default errorBoundary(RawData);
