import { Button, Icon, List, Pagination, Popup, Table } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import { useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { usePagination, useSortBy, useTable } from "react-table";

import CheckboxButton from "./checkbox-button";
import { ReactComponent as EllipsisIcon } from "@/views/reporting/img/vertical-ellipsis-icon.svg";
import { ReactComponent as ExcelIcon } from "@/views/reporting/img/xcel-icon.svg";
import { ReactComponent as FilterIcon } from "@/views/reporting/img/filter-grey.svg";
import { ReactComponent as NoDataImage } from "@/views/reporting/img/no-site.svg";
import { ReactComponent as SortingAscending } from "@/views/reporting/img/sorting-ascending.svg";
import { ReactComponent as SortingDescending } from "@/views/reporting/img/sorting-descending.svg";
import { formatDateTime, sanitizeCsvOutput } from "./functions/csv-export";

const useStyles = createUseStyles({
	container: {
		display: "flex",
		flexDirection: "column",
		backgroundColor: "white",
		borderRadius: "8px",
		justifyContent: "space-between",
		width: "calc(100vw - 109px)",
	},
	cardHeader: {
		fontFamily: "Stolzl-Medium",
		fontSize: "24px",
		fontWeight: "500",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		textAlign: "left",
		paddingTop: "5px",
		paddingLeft: "0",
		paddingBottom: "32px",
		paddingRight: "0",
		display: "flex",
		alignItems: "center",
		flexDirection: "row",
		justifyContent: "space-between",
	},
	tableHeader: {
		position: "sticky",
		top: "0",
		"& > tr > th:first-child": {
			borderTopLeftRadius: "8px !important",
		},
		"& > tr > th:last-child": {
			borderTopRightRadius: "8px !important",
		},
	},
	tableScroll: {
		flexDirection: "row",
		borderRadius: "8px",
		height: "calc(100vh - 420px)",
		overflowY: "auto",
		overflowX: "auto",
		marginBottom: "20px",
		"&::-webkit-scrollbar-track": {
			display: "none",
		},
		"& > *::-webkit-scrollbar": {
			background: "FFFFFF",
		},

		"& > *::-webkit-scrollbar-thumb": {
			background: "E3E7E9",
		},
	},
	tallerTableScroll: {
		composes: "$tableScroll",
		height: "calc(200vh)",
	},
	headerButton: {
		padding: "0 !important",
		height: "40px !important",
		width: "40px !important",
		backgroundColor: "white !important",
		"&:hover > svg > path": {
			fill: "#353430",
		},
	},
	headerCell: {
		backgroundColor: "#2e475f !important",
		color: "white !important",
		fontFamily: "Stolzl !important",
		fontSize: "17px !important",
		fontWeight: "500 !important",
		fontStretch: "normal !important",
		fontStyle: "normal !important",
		lineHeight: "normal !important",
		letterSpacing: "normal !important",
		textAlign: "left !important",
		borderLeft: "1px solid #c4c4c4 !important",
		minWidth: "150px",
		height: "80px",
		padding: "0 !important",
	},
	headerCellContent: {
		display: "flex",
		alignItems: "center",
		flexDirection: "row",
		paddingLeft: "12px",
		justifyContent: "space-between",
		"& > span:first-child": {
			paddingRight: "12px",
		},
		"& > div": {
			width: "18px",
			height: "18px",
			marginRight: "12px",
			"& > div": {
				"& > svg": {
					"& > path": {
						fill: "#294652",
					},
				},
			},
		},
		"&:hover": {
			"&  > div > div > svg": {
				"& > path": {
					fill: "#E3E7E9",
				},
			},
		},
	},
	tableRow: {
		maxHeight: "40px !important",
		"& > td": {
			paddingTop: "8px !important",
			paddingBottom: "8px !important",
		},
	},
	filterItems: {
		maxHeight: "300px",
		width: "302px",
		overflow: "auto",
	},
	noDataTable: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		width: "100%",
		justifyContent: "space-between",
		height: "100%",
		fontFamily: "Stolzl",
		fontSize: "28px",
		fontWeight: "normal",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		textAlign: "left",
		color: "#353430",
		"& > div:first-child": {
			width: "100%",
			height: "80px",
			backgroundColor: "#294652",
		},
		"& > div:last-child": {
			width: "100%",
			height: "80px",
		},
		"& > div": {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			"& > span": {
				marginTop: "20px",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				"& > svg": {
					width: "35px",
					height: "35px",
					"& > path": {
						fill: "#353430",
					},
				},
			},
		},
	},
	export: {
		color: "black",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		fontFamily: "Stolzl",
		fontSize: "14px",
		fontWeight: "normal",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		"& > svg": {
			marginRight: "16px",
		},
	},
	tableContainer: {
		display: "flex",
		flexDirection: "row",
	},
	footer: {
		display: "flex",
		alignItems: "center",
		flexDirection: "row",
		justifyContent: "space-between",
		fontFamily: "Stolzl",
		fontSize: "13px",
		fontWeight: "normal",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		textAlign: "left",
		"& > div:first-child": {
			"& > span": {
				marginRight: "10px",
			},
		},
		"& > div:last-child": {
			"& > span": {
				marginRight: "20px",
			},
		},
	},
	footerBtn: {
		padding: "12px !important",
		color: "#000 !important",
		fontSize: "13px !important",
		fontFamily: "Stolzl !important",
		borderRadius: "8px !important",
	},
	footerButton: {
		composes: "$footerBtn",
		backgroundColor: "white !important",
		"&:hover": {
			backgroundColor: "#eaecee !important",
		},
	},
	activeFooterButton: {
		composes: "$footerBtn",
		backgroundColor: "#eaecee !important",
	},
	pagination: {
		"& > a:first-child": {
			borderBottomLeftRadius: "8px !important",
			borderTopLeftRadius: "8px !important",
		},
		"& > a:last-child": {
			borderBottomRightRadius: "8px !important",
			borderTopRightRadius: "8px !important",
		},
	},
});
const defaultPropGetter = () => ({});

function ReportingTable({
	columns,
	data,
	header,
	dateOptions,
	extraHeader,
	enableOperatorUserName,
	taller = false,
	filterable = false,
	getColumnProps = defaultPropGetter,
	getCellProps = defaultPropGetter,
}) {
	const [openFilter, setOpenFilter] = useState(false);
	const [openActions, setOpenActions] = useState(false);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		// canPreviousPage,
		// canNextPage,
		// pageOptions,
		pageCount,
		gotoPage,
		// nextPage,
		// previousPage,
		allColumns,
		visibleColumns,
		getToggleHideAllColumnsProps,
		setPageSize,
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data,
			initialState: {
				sortBy: [
					{
						id: "utcDateTime",
						desc: true,
					},
				],
				pageIndex: 0,
				pageSize: 100,
			},
			disableSortRemove: true,
		},
		useSortBy,
		usePagination,
	);

	const theme = useTheme();
	const classes = useStyles({ theme });
	const intl = useIntl();

	function exportData() {
		if (!data || !data.length) return "";
		const items = data;
		let excludeHeaders = ["siteId", "tenantId", "siteName", "machineId", "uom"];
		if (!enableOperatorUserName) excludeHeaders.push("operatorUserName");

		const header = Object.keys(items[0]).filter((h) => !excludeHeaders.includes(h));

		const headerWithText = header.map((k) => {
			const column = columns.find((c) => c.accessor === k);
			if (column) {
				return `"${column.Header}"`;
			}
			return `"Timestamp"`;
		});

		const csv = [
			headerWithText.join(","),
			...items.map((row) =>
				header
					.map((fieldName) => {
						const column = columns.find((c) => c.accessor === fieldName);
						var value = row[fieldName] ?? "";
						if (!column || !column.dangerousField) {
							if (fieldName === "utcDateTime") value = formatDateTime(row[fieldName]);
							return `"${value}"`;
						}

						return sanitizeCsvOutput(value);
					})
					.join(","),
			),
		].join("\r\n");

		return "data:text/plain;charset=utf-8," + encodeURIComponent(csv);
	}

	const allChecked = allColumns.every((c) => c.getToggleHiddenProps().checked === true);

	return (
		<div className={classes.container}>
			<div className={classes.cardHeader}>
				<div>{header}</div>
				<div>
					{extraHeader}
					{filterable && (
						<Button className={classes.headerButton}>
							<Popup
								flowing
								basic
								on="click"
								onClose={() => setOpenFilter(false)}
								onOpen={() => setOpenFilter(true)}
								open={openFilter}
								position="bottom right"
								trigger={<FilterIcon />}
							>
								<List relaxed="very" size="medium" fluid>
									<List.Item>
										<span>
											<FormattedMessage id="Show Hide Columns"></FormattedMessage>
										</span>
									</List.Item>
									<List.Item>
										<CheckboxButton color="black" {...getToggleHideAllColumnsProps()}>
											{allChecked ? <FormattedMessage id="Deselect All" /> : <FormattedMessage id="Select All" />}
										</CheckboxButton>
									</List.Item>
									<div className={classes.filterItems}>
										{allColumns.map((column) => {
											return (
												<List.Item key={column.id}>
													<CheckboxButton color="black" {...column.getToggleHiddenProps()}>
														{column.Header}
													</CheckboxButton>
												</List.Item>
											);
										})}
									</div>
								</List>
							</Popup>
						</Button>
					)}
					<Button className={classes.headerButton}>
						<Popup
							flowing
							basic
							on="click"
							onClose={() => setOpenActions(false)}
							onOpen={() => setOpenActions(true)}
							open={openActions}
							position="bottom right"
							trigger={<EllipsisIcon />}
						>
							<List relaxed="very" size="medium" fluid>
								<List.Item>
									<a
										download={`${header ?? "raw"}_${dateOptions.start.replaceAll(
											"-",
											".",
										)}-${dateOptions.end.replaceAll("-", ".")}.csv`}
										href={exportData()}
										className={classes.export}
										onClick={() => setOpenActions(false)}
									>
										<ExcelIcon />
										<FormattedMessage id="Export Data" />
									</a>
								</List.Item>
							</List>
						</Popup>
					</Button>
				</div>
			</div>
			<div className={taller ? classes.tallerTableScroll : classes.tableScroll}>
				{visibleColumns.length === 0 ? (
					<div className={classes.noDataTable}>
						<div></div>
						<div>
							<NoDataImage />
							<span>
								<FormattedMessage id="Click the" />
								<FilterIcon />
								<FormattedMessage id="to select a column" />
							</span>
						</div>
						<div></div>
					</div>
				) : (
					<Table celled striped {...getTableProps()} singleLine>
						<Table.Header className={classes.tableHeader}>
							{headerGroups.map((headerGroup, headerGroupIndex) => (
								<Table.Row key={headerGroupIndex} {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map((column, columnIndex) => {
										return (
											<Table.HeaderCell
												key={columnIndex}
												className={classes.headerCell}
												{...column.getHeaderProps(column.getSortByToggleProps())}
											>
												<div className={classes.headerCellContent}>
													<span>{column.render("Header")}</span>
													<div>
														{column.isSorted ? (
															column.isSortedDesc ? (
																<SortingDescending />
															) : (
																<SortingAscending />
															)
														) : (
															<div>
																<SortingDescending></SortingDescending>
															</div>
														)}
													</div>
												</div>
											</Table.HeaderCell>
										);
									})}
								</Table.Row>
							))}
						</Table.Header>
						<Table.Body {...getTableBodyProps()}>
							{page.map((row, index) => {
								prepareRow(row);
								return (
									<Table.Row key={index} {...row.getRowProps()} className={classes.tableRow}>
										{row.cells.map((cell, index) => (
											<Table.Cell
												key={index}
												{...cell.getCellProps([
													{
														className: cell.column.className,
														style: cell.style,
													},
													getColumnProps(cell.column),
													getCellProps(cell),
												])}
											>
												{cell.render("Cell")}
											</Table.Cell>
										))}
									</Table.Row>
								);
							})}
						</Table.Body>
					</Table>
				)}
			</div>
			<div className={classes.footer}>
				<div>
					<span>
						<FormattedMessage id="Items per page" />
					</span>
					<Button
						className={pageSize === 100 ? classes.activeFooterButton : classes.footerButton}
						onClick={() => setPageSize(100)}
					>
						100
					</Button>
					{pageCount > 1 && (
						<Button
							className={pageSize === 200 ? classes.activeFooterButton : classes.footerButton}
							onClick={() => setPageSize(200)}
						>
							200
						</Button>
					)}
				</div>
				<div>
					<span>
						<FormattedMessage
							id="pageCountText"
							defaultMessage="Page {currentPage} of {totalPages} ({totalRecords} items)"
							values={{
								currentPage: pageIndex + 1,
								totalPages: pageCount,
								totalRecords: intl.formatNumber(data.length),
							}}
						/>
					</span>
					{pageCount > 1 && (
						<Pagination
							className={classes.pagination}
							defaultActivePage={pageIndex + 1}
							onPageChange={(e, { activePage }) => gotoPage(activePage - 1)}
							ellipsisItem={{
								content: <Icon name="ellipsis horizontal" />,
								icon: true,
							}}
							firstItem={{
								content: <Icon name="angle double left" />,
								icon: true,
							}}
							lastItem={{
								content: <Icon name="angle double right" />,
								icon: true,
							}}
							prevItem={{
								content: <Icon name="angle left" />,
								icon: true,
							}}
							nextItem={{
								content: <Icon name="angle right" />,
								icon: true,
							}}
							totalPages={pageCount}
						/>
					)}
				</div>
			</div>
		</div>
	);
}

export default ReportingTable;
