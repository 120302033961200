import { Icon } from "semantic-ui-react";
import { useIntl } from "react-intl";
import { Link, Outlet, useLocation } from "react-router";
import { useContext } from "react";
import styled from "styled-components";

import CloudPlatformLogo from "@/assets/images/PackNetCloud_Platform_DarkBG.svg";
import ErrorsAndWarnings from "@/components/errors-and-warnings";
import { UserContext } from "@/components/user-context";
import { hasAnyAdminPermission, hasAnyReportingPermission } from "@/views/configuration/roles/role-utils";

import Flex from "@/components/flex";
import { BodyRegular } from "@/components/typography";
import { FullScreenContext } from "@/contexts/fullscreen-context";
import UserMenu from "./user-menu";
import { addOpacity } from "@/theme/utils";
import TenantSelection from "./tenant-select";

const NavbarContainer = styled(Flex)`
	background-color: ${({ theme }) => theme.darkBlueBackground};
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
	padding: 0 1rem;
	height: ${({ theme }) => theme.measurements.navBarHeight};
	width: 100%;
`;

export const NavItem = styled(Flex)`
	&&& {
		height: 100%;
		padding: 1rem;
		align-items: center;
		color: ${({ theme }) => theme.colors.white};
		border-bottom: ${({ $active, theme }) => ($active ? `3px solid ${theme.white}` : undefined)};
		background-color: ${({ $active, theme }) => ($active ? `${addOpacity(theme.white, 0.12)}` : undefined)};
		opacity: ${({ $active }) => ($active ? undefined : "70%")};

		&:hover {
			cursor: pointer;
			opacity: 100%;
		}
	}
`;

export const NavIcon = styled(Icon)`
	&&& {
		color: white;
		margin: 0;
	}
`;

const Navbar = () => {
	const { pathname } = useLocation();
	const intl = useIntl();

	const { currentUser } = useContext(UserContext);

	// Remove displayFullscreen logic when ready
	const displayFullscreen = false;
	const { FullScreenControl } = useContext(FullScreenContext);

	const hasDashboardRole =
		currentUser && (currentUser.role.includes("accessdashboard") || currentUser.role.includes("accessmachinehistory"));

	const hasReportingRoles = currentUser && hasAnyReportingPermission(currentUser.role);
	const hasConfigurationRoles = currentUser?.role ? hasAnyAdminPermission(currentUser.role) : false;

	const isSiteSelect = pathname?.toLowerCase() === "/site-select";
	const displayNavbar = currentUser?.role?.length > 0 && !isSiteSelect;

	const isActive = (link) => {
		const linkText = link.replace("/", "");
		return pathname
			.toLowerCase()
			.split("/")
			.filter((p) => p !== "")
			.includes(linkText);
	};

	const navItems = [
		{
			link: "/dashboard",
			text: intl.formatMessage({ id: "Dashboard" }),
			authorization: hasDashboardRole,
			licensed: "pack-net-dashboard",
		},
		{
			link: "/production",
			text: intl.formatMessage({ id: "Production" }),
			authorization: true,
			licensed: "pack-net-production",
		},
		{
			link: "/configuration",
			text: intl.formatMessage({ id: "Configuration" }),
			authorization: hasConfigurationRoles,
		},
		{
			link: "/dimensioning",
			text: intl.formatMessage({ id: "Dim" }),
			authorization: true,
			licensed: "dim-allow",
		},
		{
			link: "/cube",
			text: intl.formatMessage({ id: "Cube" }),
			authorization: true,
			licensed: "cube-allow",
		},
		{
			link: "/reporting",
			text: intl.formatMessage({ id: "Report" }),
			authorization: hasReportingRoles,
			licensed: "pack-net-reporting",
		},
		{
			link: "/vmi",
			text: intl.formatMessage({ id: "VMI" }),
			authorization: true,
			licensed: "pack-net-vmi",
		},
		{
			link: "/ps-now",
			text: intl.formatMessage({ id: "Packsize Now!" }),
			authorization: hasReportingRoles,
			licensed: "PackNet-PS-Now",
		},
		{
			link: "/simulators",
			text: intl.formatMessage({ id: "Simulators" }),
			authorization: true,
			licensed: "machines-allow-simulator",
		},
	];

	const licensedTabs = navItems.filter((t) => {
		if (!t.licensed) return true;
		else if (!currentUser) return false;
		else return currentUser["ff-" + t.licensed] === "True";
	});
	const authorizedTabs = licensedTabs.filter((t) => {
		if (t.authorization === null || t.authorization === undefined) return true;
		else return t.authorization;
	});

	if (!displayNavbar) return <></>;

	return (
		<div style={{ height: "100%", width: "100%" }}>
			<NavbarContainer as="nav">
				<Flex justifyBetween grow>
					<Flex gap={16}>
						<Flex>
							<img data-cy="packnet" src={CloudPlatformLogo} style={{ width: "180px" }} alt="PackNet" />
						</Flex>
						{authorizedTabs.map((tab) => {
							return (
								<NavItem key={tab.link} as={Link} to={tab.link} $active={isActive(tab.link)}>
									<BodyRegular>{tab.text}</BodyRegular>
								</NavItem>
							);
						})}
					</Flex>
					<Flex alignCenter gap={16}>
						<Flex>
							<ErrorsAndWarnings />
						</Flex>
						<TenantSelection />
						{currentUser && currentUser["ff-pack-net-production"] === "True" && (
							<NavItem as={Link} to={"/search/"} $active={isActive("/search")} data-cy="search-button">
								<NavIcon name="search" size="large" />
							</NavItem>
						)}
						<UserMenu />
						{displayFullscreen && (
							<NavItem data-cy="full-screen-button">
								<FullScreenControl />
							</NavItem>
						)}
					</Flex>
				</Flex>
			</NavbarContainer>
			<Outlet></Outlet>
		</div>
	);
};

export default Navbar;
