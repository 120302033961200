import { FormattedMessage } from "react-intl";
import { Fragment, useContext } from "react";
import { createUseStyles, useTheme } from "react-jss";
import DimDataContext from "../contexts/dimensioning-data-context";

import { ReactComponent as Error } from "@/assets/images/error.svg";
import { Form } from "semantic-ui-react";
import RadioButton from "@/components/radio-button";

const useStyles = createUseStyles((theme) => ({
	...theme.configurationDialog,
	prompt: {
		paddingBottom: "8px",
	},
	dimensionParameters: {
		paddingTop: "16px",
		display: "flex !important",
		flexDirection: "row",
		flexWrap: "wrap",
		alignItems: "flex-start",
		alignContent: "stretch",
		"& .field": {
			marginRight: "1rem !important",
			width: "7em",

			"& input": {
				textAlign: "center",
				marginTop: "16px",
			},
		},
	},
	inputErrorIcon: {
		top: "26px",
		right: "11px",
		position: "absolute",
	},
	radioGroup: {
		display: "flex",
		alignItems: "flex-start",
		"& > *": {
			marginRight: "32px",
		},
	},
	radioBlock: {
		marginTop: "8px",
		paddingTop: "2px",
		display: "block !important",
		fontFamily: "Stolzl-Book !important",
	},
	note: {
		fontFamily: "Stolzl-Book !important",
		color: "#7c7b79",
		size: "12px",
		paddingTop: "16px",
	},
}));

export const distoUnits = {
	" in": 0,
	" mm": 0,
};

export function isDistoInput(input) {
	return input && input.length > 3 && input.substring(input.length - 3).toLowerCase() in distoUnits;
}

const DimensioningVoidFill = ({ product, setProduct, errors }) => {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const dimCtx = useContext(DimDataContext);

	function adjustDistoValue(value) {
		let newValue = value;
		if (isDistoInput(newValue)) {
			const dim_split = newValue.split(" ");
			dim_split[0] -= dimCtx.customerConfiguration.productionSettings.distoOffset;
			newValue = dim_split[0];
		}

		return newValue;
	}

	return (
		<Fragment>
			<div className={classes.prompt}>
				<FormattedMessage id="Can the product contain?" />
			</div>
			<div className={classes.radioGroup}>
				<RadioButton
					name="canContain"
					className={classes.radioBlock}
					value="yes"
					checked={product?.canContain ?? false}
					onChange={() => setProduct({ ...product, canContain: true })}
				>
					<FormattedMessage id="Yes" />
				</RadioButton>
				<RadioButton
					name="canContain"
					className={classes.radioBlock}
					value="no"
					checked={!product?.canContain}
					onChange={() => setProduct({ ...product, canContain: false })}
				>
					<FormattedMessage id="No" />
				</RadioButton>
			</div>

			{product.canContain && (
				<div>
					<div className={classes.dimensionParameters}>
						<Form.Input
							icon
							min={0}
							fluid
							label="Length*"
							placeholder="Length"
							checkRange={true}
							value={product?.voidFill?.length ?? 0}
							onBlur={(event) => {
								const newValue = adjustDistoValue(event.target.value);
								setProduct({
									...product,
									voidFill: { ...product.voidFill, length: newValue },
								});
							}}
							onChange={(_, { value }) =>
								setProduct({
									...product,
									voidFill: { ...product.voidFill, length: value },
								})
							}
							error={!!errors.voidFillLength}
						>
							<input />
							{errors.voidFillLength && (
								<i className="icon">
									<Error className={classes.inputErrorIcon} />
								</i>
							)}
						</Form.Input>
						<Form.Input
							icon
							min={0}
							fluid
							label="Width*"
							placeholder="Width"
							value={product?.voidFill?.width ?? 0}
							onBlur={(event) => {
								const newValue = adjustDistoValue(event.target.value);
								setProduct({
									...product,
									voidFill: { ...product.voidFill, width: newValue },
								});
							}}
							onChange={(_, { value }) =>
								setProduct({
									...product,
									voidFill: { ...product.voidFill, width: value },
								})
							}
							error={!!errors.voidFillWidth}
						>
							<input />
							{errors.voidFillWidth && (
								<i className="icon">
									<Error className={classes.inputErrorIcon} />
								</i>
							)}
						</Form.Input>
						<Form.Input
							icon
							min={0}
							fluid
							label="Height*"
							placeholder="Height"
							value={product?.voidFill?.height ?? 0}
							onBlur={(event) => {
								const newValue = adjustDistoValue(event.target.value);
								setProduct({
									...product,
									voidFill: { ...product.voidFill, height: newValue },
								});
							}}
							onChange={(_, { value }) =>
								setProduct({
									...product,
									voidFill: { ...product.voidFill, height: value },
								})
							}
							error={!!errors.voidFillHeight}
						>
							<input />
							{errors.voidFillHeight && (
								<i className="icon">
									<Error className={classes.inputErrorIcon} />
								</i>
							)}
						</Form.Input>
					</div>
					<div className={classes.note}>
						<FormattedMessage id="DimVoidFillNotes" />
					</div>
				</div>
			)}
		</Fragment>
	);
};

export default DimensioningVoidFill;
