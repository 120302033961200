import axios from "axios";
import { readLocalStorage } from "./local-storage";
import packNetAxios from "@/lib/api-client.js";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { baseRoutes } from "@/api/base-routes.js";

export async function getPhysicalDesign(designId, settings, setCallBack) {
	const token = readLocalStorage("BEARER");

	const body = {
		rotation: settings.rotation,
		tileCount: 1,
		Carton: {
			designId: designId,
			dimensions: {
				length: settings.length,
				width: settings.width,
				height: settings.height,
				values: settings.designParameters,
			},
		},
		Corrugate: {
			width: 42,
			flute: "C",
			thickness: 0.158,
			quality: 1,
			alias: "42",
		},
	};
	const response = await axios.post(baseRoutes.packagingDesign + "/v1.0/physicalDesign", body, {
		headers: {
			Authorization: token,
		},
	});
	const data = await response.data;
	setCallBack(data);
}

export async function getPreview(design) {
	const token = readLocalStorage("BEARER");
	const response = await axios.post(baseRoutes.packagingDesign + "/v1.0/LegacyPackagingDesign/preview", design, {
		headers: {
			Authorization: token,
		},
	});
	const data = await response.data;
	return data;
}

export async function getDesigns() {
	try {
		const response = await packNetAxios.get(baseRoutes.packagingDesign + "/v1.0/packagingdesigns");
		const data = await response.data;
		return data.map((d) => ({ ...d, DesignId: d.DesignId ?? d.designId }));
	} catch (err) {
		return { error: err };
	}
}

export const usePackagingDesigns = ({ queryConfig = {} } = {}) => {
	return useQuery({
		...queryOptions({
			queryKey: ["packaging-designs"],
			queryFn: getDesigns,
		}),
		...queryConfig,
	});
};

export async function uploadDesignXml({ ezdDesign }) {
	try {
		const response = await packNetAxios.post(
			baseRoutes.packagingDesign + "/v1.0/LegacyPackagingDesign/fromXmlString",
			ezdDesign,
		);
		const data = response.data;
		return { data, success: true };
	} catch (err) {
		return {
			data: { message: err },
			success: false,
		};
	}
}

export async function updateEditedDesign({ design, bearer }) {
	try {
		const results = await axios.put(`/packagingdesignapi/api/v1.0/packagingdesigns/${design.id}`, design, {
			headers: {
				Authorization: `${bearer}`,
			},
		});

		return await results.data;
	} catch (err) {
		console.error(err);
	}
}

export async function deleteDesign(id) {
	try {
		const result = await axios.delete(`/packagingdesignapi/api/v1.0/packagingdesigns/${id}`, {
			headers: {
				Authorization: readLocalStorage("BEARER"),
			},
		});
		return result;
	} catch (err) {
		console.error(err);
	}
}
