import { FormattedMessage } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";

import errorBoundary from "@/components/error-boundary";
import UnexpectedError from "./unexpected-error";
import NoSchedules from "./no-schedules";

const useStyles = createUseStyles({
	cardHeader: {
		fontFamily: "Stolzl-Medium",
		fontSize: "24px",
		fontWeight: "500",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		textAlign: "left",
		paddingLeft: "0",
		paddingBottom: "32px",
		paddingRight: "0",
	},
	subCardHeader: {
		display: "flex",
		flexGrow: "0",
		fontFamily: "Stolzl",
		fontSize: "13px",
		fontWeight: "normal",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		textAlign: "left",
		color: "#a2adb3",
		justifyContent: "space-between",
		textTransform: "uppercase",
		paddingBottom: "20px",
	},
	baseCard: {
		display: "flex",
		flexDirection: "column",
		backgroundColor: "white",
		borderRadius: "8px",
		height: "max-content",
	},
	card: {
		composes: ["$baseCard"],
		padding: "20px",
	},
	dateRange: {
		flexGrow: "0",
		fontFamily: "Stolzl",
		fontSize: "15px",
		fontWeight: "500",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		textAlign: "left",
		color: "#353430",
		marginTop: "auto",
	},
	footer: {
		display: "flex",
		justifyContent: "space-between",
	},
	footerText: {
		color: "#a2adb3",
		fontSize: "16px",
		fontWeight: "normal",
		letterSpacing: "normal",
		textAlign: "left",
	},
	showMore: {
		cursor: "pointer",
		fontFamily: "Stolzl",
		fontSize: "17px",
		fontWeight: "bold",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		textAlign: "left",
		paddingBottom: "20px",
	},
	subtitleWithIcon: {
		display: "flex",
	},
});

function DashboardCard({
	title,
	titlePopup,
	dateRange,
	limit,
	showNoSchedules = false,
	showNoSchedulesImage = true,
	setInternalNavigation,
	navigationDestination,
	subtitles,
	showHeader = true,
	showFooter = true,
	footerText = "",
	children,
}) {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const defaultColor = "#353430";

	function getDateRangeText(dateRange) {
		if (!dateRange || !dateRange.id) return "";
		const startSplits = dateRange.start.split("T")[0].split("-");
		const start = new Date(startSplits[0], Number(startSplits[1]) - 1, startSplits[2]);
		if (["Custom", "Last30Days", "Last7Days"].includes(dateRange.id)) {
			const endSplits = dateRange.end.split("T")[0].split("-");
			const end = new Date(endSplits[0], Number(endSplits[1]) - 1, endSplits[2]);
			return `${start.toLocaleDateString()} - ${end.toLocaleDateString()}`;
		} else {
			return `${start.toLocaleDateString()}`;
		}
	}

	const contentBody = () => {
		if (showNoSchedules) {
			return <NoSchedules showHeaderImage={showNoSchedulesImage} />;
		}
		return children;
	};

	return (
		<div className={classes.card}>
			{showHeader && (
				<div className={`${classes.cardHeader}`}>
					{titlePopup ? titlePopup : <>{typeof title === "string" ? <FormattedMessage id={title} /> : title}</>}
				</div>
			)}
			{subtitles && subtitles.length && (
				<div className={classes.subCardHeader}>
					{subtitles.map((subtitle, i) => {
						if (typeof subtitle === "object" && subtitle !== null) {
							return (
								<span
									className={`${classes.subtitleWithIcon}`}
									key={`dashboard-subtitle-${i}`}
									onClick={subtitle.onClick}
								>
									<FormattedMessage id={subtitle.id} />
									<div style={{ marginLeft: "3px" }}>{subtitle.icon()}</div>
								</span>
							);
						} else {
							return (
								<span key={`dashboard-subtitle-${i}`}>
									<FormattedMessage id={subtitle} />
								</span>
							);
						}
					})}
				</div>
			)}
			{contentBody()}
			{limit < 1000 && navigationDestination && (
				<span
					className={classes.showMore}
					style={{ color: defaultColor }}
					onClick={() => setInternalNavigation(navigationDestination)}
				>
					<FormattedMessage id="Show All" />
				</span>
			)}
			{showFooter && (
				<div className={classes.footer}>
					<div className={classes.dateRange}>{getDateRangeText(dateRange)}</div>
					<span className={classes.footerText}>
						{footerText && <FormattedMessage id={footerText} />}
						{!footerText && ""}
					</span>
				</div>
			)}
		</div>
	);
}

// eslint-disable-next-line react-refresh/only-export-components
export default errorBoundary(DashboardCard, () => <UnexpectedError />);
