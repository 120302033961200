import baseRoutes from "./base-routes.json";
import preprodRoutes from "./base-routes.preprod.json";
import prod1Routes from "./base-routes.prod1.json";
import prod2Routes from "./base-routes.prod2.json";

const getRoutes = (routingEnv = import.meta.env.VITE_ROUTING_ENV ?? "${{ROUTING_ENV}}") => {
	switch (routingEnv) {
		case "preprod":
			return preprodRoutes;
		case "prod1":
			return prod1Routes;
		case "prod2":
			return prod2Routes;
		default:
			return baseRoutes;
	}
};

const routes = getRoutes();

export { routes as baseRoutes };
