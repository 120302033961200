import { useEffect, useState } from "react";
import * as signalR from "@microsoft/signalr";

export default function useSignalRHub({ hubUrl, token }) {
	const [hubConnection, setHubConnection] = useState(undefined);
	const [hubConnectionEstablished, setHubConnectionEstablished] = useState(false);
	const [usePolling, setUsePolling] = useState(true);

	useEffect(() => {
		if (!token) {
			return;
		}

		const options = {
			transport: signalR.HttpTransportType.WebSockets,
			logger: signalR.LogLevel.Information,
			accessTokenFactory: () => token.replace("BEARER ", ""),
		};

		const connection = new signalR.HubConnectionBuilder()
			.withUrl(hubUrl, options)
			.withAutomaticReconnect()
			.configureLogging(signalR.LogLevel.Debug)
			.build();
		setHubConnection(connection);
	}, [token, hubUrl]);

	// Start and stop the SignalR hub connection
	useEffect(() => {
		if (!hubConnection) {
			return;
		}

		hubConnection.start().then(() => {
			setHubConnectionEstablished(true);
			setUsePolling(false);
		});

		hubConnection.onclose(() => {
			setHubConnectionEstablished(false);
			setUsePolling(true);
		});
		hubConnection.onreconnected(() => {
			setHubConnectionEstablished(true);
			setUsePolling(false);
		});
		hubConnection.onreconnecting(() => {
			setHubConnectionEstablished(false);
			setUsePolling(true);
		});

		return () => {
			setUsePolling(true);
			hubConnection.stop();
		};
	}, [hubConnection]);

	return { hubConnection, hubConnectionEstablished, usePolling };
}
