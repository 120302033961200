import { createContext, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";

export const PublicSettingsContext = createContext({
	instrumentationKey: "",
	version: "",
	versionOnLoad: "",
	serviceStatusUrl: "",
	setRefreshSettingsEnabled: () => {},
});

export const publicSettingsQueryKey = ["publicFacingWebSiteSettings"];

function PublicSettingsProvider({ children }) {
	const [refreshSettingsEnabled, setRefreshSettingsEnabled] = useState(true);
	const [versionOnLoad, setVersionOnLoad] = useState("");

	const queryFn = async () => {
		const response = await fetch(`${window.location.origin}/PackNet/publicFacingWebSiteSettings.jsonconfig`);
		const result = await response.json();
		if (!versionOnLoad) setVersionOnLoad(result.version);
		return result;
	};

	const { data } = useQuery({
		queryKey: publicSettingsQueryKey,
		queryFn,
		enabled: refreshSettingsEnabled,
		refetchInterval: 300000, //5 min
	});

	const contextValue = useMemo(
		() => ({
			instrumentationKey: data?.instrumentationKey ?? "",
			version: data?.version ?? "",
			serviceStatusUrl: data?.serviceStatusUrl ?? "",
			launchDarklyKey: data?.launchDarklyKey ?? "",
			versionOnLoad,
			setRefreshSettingsEnabled,
		}),
		[data?.instrumentationKey, data?.serviceStatusUrl, data?.launchDarklyKey, data?.version, versionOnLoad],
	);

	return <PublicSettingsContext.Provider value={contextValue}>{children}</PublicSettingsContext.Provider>;
}

export default PublicSettingsProvider;
