import React, { useCallback, useContext, useMemo } from "react";
import { readLocalStorage, removeLocalStorage, writeLocalStorage } from "@/api/local-storage";

import { allPermissions } from "@/views/configuration/roles/role-utils";
import { isFeatureFlagEnabled } from "@/utils/auth";
import { jwtDecode } from "jwt-decode";
import { FullStory, isInitialized } from "@fullstory/browser";
import { getApplicationInsights } from "@/contexts/app-insights";

export async function setSsoUser(encodedToken, setCurrentUser) {
	let loggedInUser = await processToken(encodedToken);
	let totallyUpdatedCurrentUser = await setCurrentUser(loggedInUser);
	//We do not know why the next line is in this context
	removeLocalStorage("currentMachineGroup");
	return totallyUpdatedCurrentUser;
}

export const setFullStoryIdentity = async (decodedToken) => {
	if (isInitialized()) {
		try {
			await FullStory("setIdentityAsync", {
				uid: decodedToken.id,
				properties: {
					userGuid: decodedToken.id,
					tenantId: decodedToken.Tenant,
					customerId: decodedToken.CustomerId,
					siteId: decodedToken.SiteId,
				},
			});
		} catch (e) {
			console.error("Error setting FullStory identity", e);
		}
	}
};

export const setAppInsightsIdentity = (decodedToken) => {
	let applicationInsights = getApplicationInsights(); // may be null before it has been initialized
	applicationInsights?.setAuthenticatedUserContext(decodedToken.id, decodedToken.tid, true);
};

const deleteAppInsightsIdentity = () => {
	let applicationInsights = getApplicationInsights(); // may be null before it has been initialized
	applicationInsights?.clearAuthenticatedUserContext();
};

export const processToken = async (encodedToken) => {
	const decodedToken = jwtDecode(encodedToken);
	decodedToken.token = `BEARER ${encodedToken}`;
	writeLocalStorage("BEARER", decodedToken.token);
	await setFullStoryIdentity(decodedToken);
	setAppInsightsIdentity(decodedToken);

	return normalizeRoles(decodedToken);
};

export const logout = (logoutReason, redirect) => {
	console.info("User logged out:", logoutReason);
	deleteAppInsightsIdentity();
	removeLocalStorage("BEARER");
	removeLocalStorage("currentMachineGroup");
	removeLocalStorage("LOCALTOKENEXPIRATION");
	removeLocalStorage("dimension-products-search-term");
	removeLocalStorage("preAuth");
	window.location.href = redirect ?? "/PackNet/logged-out";
};

export const normalizeRoles = (decodedToken) => {
	if (!decodedToken) return decodedToken;
	else if (!decodedToken.role) decodedToken.role = [];
	else if (typeof decodedToken.role === "string" || decodedToken.role instanceof String)
		decodedToken.role = [decodedToken.role.toLowerCase()];
	else decodedToken.role = decodedToken.role.map((r) => r.toLowerCase());

	if (decodedToken.role.includes("admin")) {
		const all = allPermissions(decodedToken);
		return { ...decodedToken, role: all };
	}
	return decodedToken;
};

export const getExpirationFromLocalStorage = () => {
	const localExpireEpoch = readLocalStorage("LOCALTOKENEXPIRATION");
	if (localExpireEpoch) {
		const localNow = new Date().getTime();
		const localExpire = parseInt(localExpireEpoch);
		const expiresInSeconds = (localExpire - localNow) / 1000;
		console.info("Remote api call to get expire time failed. using local expire time ", expiresInSeconds);
		return expiresInSeconds;
	}

	console.info(
		"Remote api call to get expire time failed. can not use local time because it is not stored, expiring token",
	);
	const expiresInSeconds = -1;
	return expiresInSeconds;
};

export const UserContext = React.createContext({
	currentUser: {
		id: "",
		roles: [],
		role: [],
		token: "",
	},
	setCurrentUser: () => {},
	logout,
});

export const useLaunchDarkly = () => {
	const { currentUser } = useContext(UserContext);

	return useCallback((launchDarklyKey) => isFeatureFlagEnabled(currentUser, launchDarklyKey), [currentUser]);
};

export const useIsRoleEnabled = (role) => {
	const { currentUser } = useContext(UserContext);

	return useMemo(() => {
		if (currentUser?.role) return currentUser.role.includes(role.toLowerCase());
		return false;
	}, [currentUser?.role, role]);
};

export const useCanManageJobs = () => {
	const { currentUser } = useContext(UserContext);
	return useMemo(() => {
		if (currentUser?.role)
			return currentUser.role.includes("managepackagingsolutions") || currentUser.role.includes("managejobactions");
		return false;
	}, [currentUser?.role]);
};
