import Flex from "@/components/flex";
import { JobQueueContext } from "@/views/production/orders/job-queue-provider";
import { ProductionContext } from "@/contexts/production-context";
import { useContext, useState } from "react";
import Card from "@/components/card";
import { Loader } from "semantic-ui-react";
import styled, { useTheme } from "styled-components";
import { Header2 } from "@/components/typography";
import InProductionOrder from "./in-production-order";
import OrdersHeader, { getMachineStatusColor } from "@/views/production/orders/orders-header";
import { getMachineGroupStatusIcon } from "@/views/production/machine-group-control/machine-group-status-icon";
import MachineStatusMessage from "@/views/production/orders/machine-status-message/machine-status-message";
import FailedRetry from "@/components/failed-retry";
import { ExpandLess, ExpandMore } from "@styled-icons/material-rounded";
import { DarkGreyText } from "@/views/production/orders/orders";
import { FormattedMessage } from "react-intl";

const StyledCard = styled(Card)`
	&&& {
		display: flex;
		align-items: center;
		flex-direction: column;
		min-height: 288px;
		flex-shrink: 0;
		background-color: ${({ color, theme }) => color || theme.colors.white};
	}
`;

const BatchContainer = styled(Flex)`
	width: 100%;
	height: 100%;
	padding: 16px 24px;
	gap: 16px;
`;

function InProductionOrdersCard() {
	const { selectedBatches, batchesLoading, refetchEnabled } = useContext(JobQueueContext);
	const { currentMachineGroup } = useContext(ProductionContext);
	const theme = useTheme();
	const [showAllJobs, setShowAllJobs] = useState(false);
	const batchesToDisplay = showAllJobs ? selectedBatches : selectedBatches.slice(0, 2);
	var jobsInProgress = !batchesLoading && selectedBatches && selectedBatches.length > 0;

	if (!refetchEnabled) return <FailedRetry retry={() => window.location.reload()} />;

	return (
		<StyledCard
			padding={false}
			color={
				!jobsInProgress
					? getMachineStatusColor({
							status: currentMachineGroup.status,
							productionEnabled: currentMachineGroup.productionEnabled,
							theme,
						})
					: null
			}
		>
			<OrdersHeader />
			{batchesLoading && <Loader active inline="centered" data-testid="loader" />}
			{!jobsInProgress && (
				<Flex grow alignCenter justifyCenter column gap={16}>
					{getMachineGroupStatusIcon({
						status: currentMachineGroup.status,
						productionEnabled: currentMachineGroup.productionEnabled,
						useColor: false,
						size: "big",
					})}
					<Header2>
						<MachineStatusMessage />
					</Header2>
				</Flex>
			)}
			{jobsInProgress && (
				<BatchContainer column justifyBetween>
					{batchesToDisplay.map((batch) => (
						<InProductionOrder selectedBatch={batch} key={batch.batchId} />
					))}
				</BatchContainer>
			)}
			{jobsInProgress && selectedBatches.length > 2 && (
				<Flex justifyCenter alignCenter style={{ paddingBottom: 8 }}>
					<Flex justifyCenter alignCenter onClick={() => setShowAllJobs(!showAllJobs)} style={{ cursor: "pointer" }}>
						{showAllJobs && (
							<>
								<ExpandLess size={24} color={theme.colors.darkGreyText} />
								<DarkGreyText style={{ whiteSpace: "nowrap" }}>
									<FormattedMessage id="Show less" />
								</DarkGreyText>
								<ExpandLess size={24} color={theme.colors.darkGreyText} />
							</>
						)}
						{!showAllJobs && (
							<>
								<ExpandMore size={24} color={theme.colors.darkGreyText} />
								<DarkGreyText style={{ whiteSpace: "nowrap" }}>
									<FormattedMessage id="Show more" />
								</DarkGreyText>
								<ExpandMore size={24} color={theme.colors.darkGreyText} />
							</>
						)}
					</Flex>
				</Flex>
			)}
		</StyledCard>
	);
}

export default InProductionOrdersCard;
