import { usePackagingDesigns } from "@/api/packaging-design-api.js";
import _0100 from "@/assets/images/packaging-design/carton-0100-erected.svg";
import _0200 from "@/assets/images/packaging-design/carton-0200-erected.svg";
import _0201 from "@/assets/images/packaging-design/carton-0201-erected.svg";
import _0202 from "@/assets/images/packaging-design/carton-0202-erected.svg";
import _0203 from "@/assets/images/packaging-design/carton-0203-erected.svg";
import _0301 from "@/assets/images/packaging-design/carton-0301-erected.svg";
import _0331 from "@/assets/images/packaging-design/carton-0331-erected.svg";
import _0401 from "@/assets/images/packaging-design/carton-0401-erected.svg";
import _0403 from "@/assets/images/packaging-design/carton-0403-erected.svg";
import _0410 from "@/assets/images/packaging-design/carton-0410-erected.svg";
import _0412 from "@/assets/images/packaging-design/carton-0412-erected.svg";
import _0421 from "@/assets/images/packaging-design/carton-0421-erected.svg";
import noImage from "@/assets/images/packaging-design/custom-erected.png";

export const usePackagingSolutionPackagingDesigns = () => {
	const {
		data: packagingDesigns,
		isLoading,
		refetch,
		isError,
		error,
	} = usePackagingDesigns({
		queryConfig: {
			queryKey: ["saved-jobs-packaging-designs"],
			initialData: [],
			select: (data) => {
				return data.map((pd) => {
					const design = {
						...pd,
						designName: pd.name,
						designId: pd.designId.toString(),
					};
					let boxStyle = design.designId.substring(0, 3);

					switch (boxStyle) {
						case "110":
							design.image2d = "";
							design.image3d = _0100;
							design.length = 20;
							design.width = 10;
							design.height = 13;
							design.x1 = 5;
							break;
						case "200":
							design.image2d = "";
							design.image3d = _0200;
							design.length = 20;
							design.width = 10;
							design.height = 13;
							design.x1 = 5;
							break;
						case "201":
							design.image2d = "";
							design.image3d = _0201;
							design.length = 20;
							design.width = 10;
							design.height = 13;
							design.x1 = 5;
							break;
						case "202":
							design.image2d = "";
							design.image3d = _0202;
							design.length = 10;
							design.width = 10;
							design.height = 10;
							design.x1 = 7;
							design.x2 = 5;
							break;
						case "203":
							design.image2d = "";
							design.image3d = _0203;
							design.length = 20;
							design.width = 8;
							design.height = 12;
							design.x1 = 5;
							break;
						case "301":
						case "452":
						case "453":
							design.image2d = "";
							design.image3d = _0301;
							design.length = 20;
							design.width = 12;
							design.height = 5;
							design.x1 = 5;
							break;
						case "331":
						case "454":
						case "455":
							design.image2d = "";
							design.image3d = _0331;
							design.length = 20;
							design.width = 8;
							design.height = 12;
							design.x1 = 5;
							break;
						case "401":
							design.image2d = "";
							design.image3d = _0401;
							design.length = 20;
							design.width = 15;
							design.height = 5;
							design.x1 = 3;
							break;
						case "403":
							design.image2d = "";
							design.image3d = _0403;
							design.length = 20;
							design.width = 15;
							design.height = 5;
							design.x1 = 3;
							break;
						case "410":
							design.image2d = "";
							design.image3d = _0410;
							design.length = 20;
							design.width = 15;
							design.height = 5;
							design.x1 = 3;
							break;
						case "412":
							design.image2d = "";
							design.image3d = _0412;
							design.length = 20;
							design.width = 15;
							design.height = 5;
							design.x1 = 3;
							break;
						case "470":
						case "471":
						case "472":
							design.image2d = "";
							design.image3d = _0421;
							design.length = 20;
							design.width = 15;
							design.height = 5;
							design.x1 = 3;
							break;
						default:
							design.image2d = "";
							design.image3d = noImage;
							design.length = 10;
							design.width = 10;
							design.height = 10;
							break;
					}

					return design;
				});
			},
		},
	});

	return { packagingDesigns, isLoading, refetch, isError, error };
};
