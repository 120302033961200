import {
	Button,
	Dropdown,
	Form,
	FormField,
	Grid,
	GridColumn,
	GridRow,
	Header,
	Icon,
	Input,
	Segment,
	SegmentGroup,
} from "semantic-ui-react";
import { useContext, useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";

import { FormattedMessage } from "react-intl";
import { ProductionContext } from "@/contexts/production-context";
import SelectMachineGroup from "../job-queue/select-machine-group";
import { UserContext } from "@/components/user-context";
import { createSignalRContext } from "react-signalr";
import errorBoundary from "@/components/error-boundary";
import { readLocalStorage } from "@/api/local-storage";
import useAxios from "@/api/useAxios";
import { baseRoutes } from "@/api/base-routes.js";

const useStyles = createUseStyles((theme) => ({
	...theme.configurationDialog,
	container: {
		padding: "24px",
	},
	scanDataLabel: {
		color: "black",
		opacity: "unset !important",
	},
	scanDataField: {
		opacity: "unset !important",
		marginLeft: "8px !important",
		width: "60px !important",
		"& div": {
			width: "120px !important",
		},
	},
	designs: {
		marginBottom: "24px",
	},
}));

const Scan2PackSignalRContext = createSignalRContext();

const Scan2PackPOC = () => {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const token = readLocalStorage("BEARER");
	const [designs, setDesigns] = useState([]);
	const [selectedDesign, setSelectedDesign] = useState({});
	const [sendStatus, setSendStatus] = useState("");
	const [xValues, setXValues] = useState([]);
	const [xValuesData, setXValuesData] = useState({});
	const [historicalData, setHistoricalData] = useState([]);
	const [scanData, setScanData] = useState({ identifier: "", length: 0, width: 0, height: 0, weight: 0 });
	const { currentUser } = useContext(UserContext);
	const { currentMachineGroup, currentProductionGroup } = useContext(ProductionContext);

	const designApi = useAxios(baseRoutes.packagingDesign + "/v1.0/packagingdesigns", token);
	const packagingSolutionsApi = useAxios(baseRoutes.import + "/v1/packagingsolutions", token);

	useEffect(() => {
		designApi.get(setDesigns, (e) => console.error("failed to fetch designs", e));
	}, []);

	useEffect(() => {
		const design = designs.find((d) => d.designId === selectedDesign);
		design?.designParameters.sort((a, b) => a.name.localeCompare(b.name));
		setXValues(design?.designParameters);
	}, [selectedDesign]);

	Scan2PackSignalRContext.useSignalREffect("OnScan2PackVersion", (version) => {
		console.info("signalr Scan2Pack - ", version);
	});

	Scan2PackSignalRContext.useSignalREffect("OnUnsubscribed", () => {
		//clearInterval(interval);
	});

	Scan2PackSignalRContext.useSignalREffect("OnScanData", (tenantId, machineId, scannedData) => {
		console.info("OnScanData", tenantId, machineId, scannedData);

		if (!Object.keys(currentMachineGroup.configuredMachinesWithMachineType).includes(machineId)) {
			return; //Dont show scanned data for machines that are not in the selected machine group
		}

		//TODO: support mm, move this to backend, translate based on user settings
		scannedData.scanData.length = scannedData.scanData.length / 100;
		scannedData.scanData.width = scannedData.scanData.width / 100;
		scannedData.scanData.height = scannedData.scanData.height / 100;
		scannedData.scanData.weight = scannedData.scanData.weight / 10;

		if (
			scannedData.scanData.length !== 0 ||
			scannedData.scanData.width !== 0 ||
			scannedData.scanData.height !== 0 ||
			scannedData.scanData.weight !== 0
		)
			setHistoricalData([scannedData, ...historicalData]);

		setScanData({ ...scannedData.scanData, identifier: scanData.identifier });
	});

	if (!Object.values(currentMachineGroup?.configuredMachinesWithMachineType).includes("Scan2Pack")) {
		return (
			<>
				<Segment basic textAlign="center">
					<Icon name="exclamation circle" size="huge" color="red" />
					<FormattedMessage id="No Scan2Pack machines are configured for this machine group" />
				</Segment>

				<SelectMachineGroup displayHeader={false} />
			</>
		);
	}

	return (
		<Scan2PackSignalRContext.Provider
			connectEnabled={!!token}
			accessTokenFactory={() => token.replace("BEARER ", "")}
			dependencies={[token]}
			url={"/scan2PackMachineApi/hubs/scan2PackMachines"}
			onOpen={() => {
				Scan2PackSignalRContext.invoke("Scan2PackVersion");
			}}
			onBeforeClose={() => new Promise((resolve) => setTimeout(() => resolve(), 1000))}
			onError={(error) => {
				console.error(`Scan2PackSignalRContext ${error}`);
				//setRefreshInterval(3000); //Fallback for when websocket is not connected
			}}
			onClosed={() => {
				//setRefreshInterval(3000); //Fallback for when websocket is not connected
			}}
		>
			<SegmentGroup horizontal className={classes.container}>
				<Segment>
					<Header>
						<FormattedMessage id="Scan2Pack Data" />
					</Header>
					<Form>
						<div>Identifier</div>
						<Form.Input
							value={scanData.identifier}
							onChange={(e) => setScanData({ ...scanData, identifier: e.target.value })}
						/>
						<Form.Group inline widths="equal">
							<FormField className={classes.scanDataField} control={Input} label="Length" value={scanData.length} />
							<FormField className={classes.scanDataField} control={Input} label="Width" value={scanData.width} />
							<FormField className={classes.scanDataField} control={Input} label="Height" value={scanData.height} />
							<FormField className={classes.scanDataField} control={Input} label="Weight" value={scanData.weight} />
						</Form.Group>
						<div>Design</div>
						<Dropdown
							className={classes.designs}
							placeholder="Select Design"
							fluid
							search
							selection
							options={designs.map((d) => {
								return { key: d.id, value: d.designId, description: d.name, text: d.designId };
							})}
							onChange={(_, { value }) => {
								setSelectedDesign(value);
							}}
						/>

						<Form.Group widths="equal" inline>
							{xValues &&
								xValues.map((x, index) => (
									<FormField
										key={index}
										className={classes.scanDataField}
										control={Input}
										label={x.name}
										value={xValuesData[x.name]}
										onChange={(_, { value }) => {
											xValuesData[x.name] = value;
											console.info(xValuesData);
											setXValuesData({ ...xValuesData });
										}}
									></FormField>
								))}
						</Form.Group>
						<Grid columns={3}>
							<GridRow stretched inline>
								<GridColumn width={4}>
									<Button
										primary
										onClick={() => {
											setSendStatus("");
											packagingSolutionsApi.postRequest(
												[
													{
														solution: {
															tenantId: currentUser.tid,
															alias: scanData.identifier,
															location: {
																machineGroup: currentMachineGroup.alias,
																productionGroup: currentProductionGroup.alias,
															},
															cartons: [
																{
																	media: {
																		dimensions: {
																			length: scanData.length,
																			width: scanData.width,
																			height: scanData.height,
																		},
																		style: selectedDesign,
																		attributes: xValuesData,
																	},
																},
															],
														},
														quantity: 1,
													},
												],
												(r) => {
													setSendStatus(
														r[0].successfullySentToQueue === true ? "Queued Successfully" : "Failed to Queue",
													);
												},
												(e) => {
													setSendStatus("Failed api call");
													console.error(e);
												},
											);
										}}
									>
										Create Package
									</Button>
								</GridColumn>
								<GridColumn width={6} verticalAlign="middle">
									{sendStatus}
								</GridColumn>
							</GridRow>
						</Grid>
					</Form>
				</Segment>
				<Segment>
					<Header>
						<FormattedMessage id="Scan History" />
					</Header>
					{historicalData.map((h, index) => (
						<div key={index}>
							Scan Time: {new Date(h.utcDateTime).toLocaleString()} Length: {h.scanData.length} Width:{" "}
							{h.scanData.width} Height: {h.scanData.height} Weight: {h.scanData.weight}
						</div>
					))}
				</Segment>
			</SegmentGroup>
		</Scan2PackSignalRContext.Provider>
	);
};

export default errorBoundary(Scan2PackPOC);
