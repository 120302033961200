import axios from "axios";
import { readLocalStorage } from "@/api/local-storage";
import { baseRoutes } from "@/api/base-routes";

let cancelToken;

export async function getBucketZFoldUsageByPlannedProductionSchedulesForSite(
	schedules,
	sites,
	machinesSelected,
	dateFilter,
) {
	const token = readLocalStorage("BEARER");
	if (typeof cancelToken != typeof undefined) {
		cancelToken.cancel("Operation canceled due to new request.");
	}

	cancelToken = axios.CancelToken.source();

	if (!sites.length || !dateFilter.id) {
		return [];
	}

	if (dateFilter && dateFilter.start) {
		const url = `${baseRoutes.reporting}/v1/ZFoldUsage/ZFoldUsageBuckets?startDate=${dateFilter.start}&endDate=${dateFilter.end}&offsetInMinutes=${dateFilter.offset}`;

		const response = await axios.post(
			url,
			{ schedules: schedules, sites: sites, machines: machinesSelected },
			{
				headers: { Authorization: token },
				cancelToken: cancelToken.token,
			},
		);
		return response.data;
	} else {
		return [];
	}
}
