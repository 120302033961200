import { Button, Modal, Table } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import { Fragment, useContext, useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";

import ConfirmModal from "../../../components/confirm-modal";
import DeleteButton from "@/components/delete-button";
import DiffView from "@/components/diff-view";
import EditButton from "@/components/edit-button";
import FailedRetry from "@/components/failed-retry";
import ListContext from "@/components/list-context";
import Loading from "@/components/loading";
import { readLocalStorage } from "@/api/local-storage";
import useAxios from "@/api/useAxios";
import { baseRoutes } from "@/api/base-routes.js";

const useStyles = createUseStyles((theme) => ({
	...theme.configurationDialog.listView,
	spacer: {
		width: "32px !important",
	},
}));

const PickZones = () => {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const token = readLocalStorage("BEARER");
	const { list, setList, setEdit, loading, setLoading, failed, setFailed } = useContext(ListContext);
	const { get, remove } = useAxios(`${baseRoutes.pickZone}/v1/pickzones`, token);
	const configurationAuditApi = useAxios(`${baseRoutes.audit}/v1/ConfigurationAudit`, token);
	const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
	const [isDiffModalOpened, setIsDiffModalOpened] = useState(false);
	const [itemToDelete, setItemToDelete] = useState({});
	const [historyData, setHistoryData] = useState({});
	const [historyItem, setHistoryItem] = useState({});

	const load = () => {
		setFailed(false);
		setLoading(true);
		setList([]);
		get(
			(data) => {
				setList(data);
				setLoading(false);
			},
			() => {
				setLoading(false);
				setFailed(true);
			},
		);
	};

	useEffect(load, []);

	const removeItem = async (item) => {
		setItemToDelete(item);
		setIsDeleteModalOpened(true);
	};

	return (
		<Fragment>
			{loading ? (
				<Loading />
			) : failed ? (
				<FailedRetry retry={load} />
			) : (
				<>
					<Modal
						size="fullscreen"
						centered={true}
						closeOnEscape={true}
						closeOnDimmerClick={true}
						className={classes.modal}
						open={isDiffModalOpened}
						onClose={async () => {
							setIsDiffModalOpened(false);
							setHistoryItem({});
						}}
					>
						<Modal.Header className={classes.title}>
							<FormattedMessage id="Pick Zones History:" /> {historyItem?.alias ?? ""}
						</Modal.Header>
						<Modal.Content className={classes.content}>
							<div className={classes.diff}>
								<DiffView data={historyData} />
							</div>
						</Modal.Content>
					</Modal>
					<ConfirmModal
						open={isDeleteModalOpened}
						onClose={async (response) => {
							if (response) {
								remove(itemToDelete.id, setList);
							}
							setIsDeleteModalOpened(false);
							setItemToDelete({});
						}}
						titleText={intl.formatMessage(
							{
								id: "Delete zone",
							},
							{
								alias: itemToDelete && itemToDelete.alias ? itemToDelete.alias : "",
							},
						)}
						detailText="This change can not be undone"
						confirmText="Delete"
						cancelText="Cancel"
					/>
					<Table basic="very" className={classes.table}>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>
									<FormattedMessage id="Name" />
								</Table.HeaderCell>
								<Table.HeaderCell>
									<FormattedMessage id="Description" />
								</Table.HeaderCell>
								<Table.HeaderCell>
									<FormattedMessage id="Status" />
								</Table.HeaderCell>
								<Table.HeaderCell className={classes.editButton} textAlign="center" collapsing>
									<FormattedMessage id="History" />
								</Table.HeaderCell>
								<Table.HeaderCell className={classes.spacer}></Table.HeaderCell>
								<Table.HeaderCell textAlign="center" collapsing>
									<FormattedMessage id="Edit" />
								</Table.HeaderCell>
								<Table.HeaderCell className={classes.spacer}></Table.HeaderCell>
								<Table.HeaderCell textAlign="right" collapsing>
									<FormattedMessage id="Delete" />
								</Table.HeaderCell>
							</Table.Row>
						</Table.Header>

						<Table.Body>
							{list.map((item) => (
								<Table.Row key={item.id}>
									<Table.Cell>{item.alias}</Table.Cell>
									<Table.Cell>{item.description}</Table.Cell>
									<Table.Cell>{item.status}</Table.Cell>
									<Table.Cell textAlign="center" collapsing className={classes.actionButtons}>
										<Button
											basic
											icon="history"
											className={classes.actionButtons}
											onClick={() => {
												configurationAuditApi.getWithUrl(`PickZone/${item.id}`, (data) => {
													setIsDiffModalOpened(true);
													setHistoryData(data);
													setHistoryItem(item);
												});
											}}
											data-testid="pick-zone-history-button"
										/>
									</Table.Cell>
									<Table.Cell className={classes.spacer}></Table.Cell>
									<Table.Cell textAlign="center" collapsing className={classes.actionButtons}>
										<EditButton onClick={() => setEdit(item)} data-testid="pick-zone-edit-button" />
									</Table.Cell>
									<Table.Cell className={classes.spacer}></Table.Cell>
									<Table.Cell textAlign="right" collapsing className={classes.actionButtons}>
										<DeleteButton onClick={() => removeItem(item)} data-testid="pick-zone-delete-button" />
									</Table.Cell>
								</Table.Row>
							))}
						</Table.Body>
					</Table>
				</>
			)}
		</Fragment>
	);
};

export default PickZones;
