import { Button, Card, Header, Segment } from "semantic-ui-react";
import { Fragment, useContext, useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";

import EmMachineControl from "./em-manual-control";
import { FormattedMessage } from "react-intl";
import Loading from "@/components/loading";
import { ProductionContext } from "@/contexts/production-context";
import { machineTypes } from "../../../constants";
import { readLocalStorage } from "@/api/local-storage";
import useMachines from "@/views/configuration/machines/use-machines";

const useStyles = createUseStyles({
	header: {
		border: "0 !important",
		alignItems: "center",
		padding: "24px 32px !important",
		margin: "0 !important",
		backgroundColor: "#f2f4f5 !important",
		borderRadius: "0px !important",
	},
	headerText: {
		color: "#353430 !important",
		fontWeight: "normal !important",
		fontSize: "40px !important",
		margin: "0 !important",
		lineHeight: "36px !important",
		letterSpacing: "-.5px",
	},
});

function ManualMachineControl() {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const token = readLocalStorage("BEARER");
	const Machines = useMachines(token);
	const [machineList, setMachineList] = useState(null);
	const [selectedMachine, setSelectedMachine] = useState(null);
	const { currentMachineGroup } = useContext(ProductionContext);
	const refreshInterval = 3000;

	const load = () => {
		Machines.get((machines) => {
			const machineDictionary = machines
				.filter(
					(m) =>
						(m.machineType && m.machineType.toLowerCase() === machineTypes.Em.toLowerCase()) ||
						m.machineType.toLowerCase() === machineTypes.X5.toLowerCase(),
				)
				.reduce((result, item) => {
					var key = item.id;
					result[key] = item;
					return result;
				}, {});
			setMachineList(machineDictionary);
		});
	};

	useEffect(load, []);

	useEffect(() => {
		if (refreshInterval && refreshInterval > 0) {
			const interval = setInterval(load, refreshInterval);
			return () => clearInterval(interval);
		}
	}, [refreshInterval]);

	useEffect(() => {
		if (!selectedMachine) return;
		if (machineList[selectedMachine.id]) setSelectedMachine(machineList[selectedMachine.id]);
	}, [machineList]);

	if (!machineList) return <Loading />;

	if (!selectedMachine && Object.keys(currentMachineGroup.configuredMachinesWithMachineType).length === 1) {
		const machineId = Object.keys(currentMachineGroup.configuredMachinesWithMachineType)[0];
		const machine = machineList[machineId];
		if (machine) setSelectedMachine(machine);
	}

	if (selectedMachine) {
		switch (selectedMachine.machineType) {
			case "Em":
				return <EmMachineControl selectedMachine={selectedMachine} />;
			default:
				return <div>No machine controls exist for {selectedMachine.machineType}</div>;
		}
	}

	const machinesInMachineGroup = Object.keys(currentMachineGroup.configuredMachinesWithMachineType)
		.filter((k) => machineList[k] !== undefined)
		.map((k) => machineList[k]);
	if (machinesInMachineGroup.length <= 0)
		return (
			<div>
				<FormattedMessage id="No machines exist in machine group with machine controls" />
			</div>
		);

	return (
		<Fragment>
			<Fragment>
				<Segment clearing className={classes.header}>
					<Header floated="left" className={classes.headerText}>
						<FormattedMessage id="Select a Machine" />
					</Header>
				</Segment>
				<Segment basic className={classes.cards}>
					<Card.Group centered className={classes.cards}>
						{machinesInMachineGroup.map((m) => (
							<Card key={m.id} onClick={() => setSelectedMachine(m)}>
								<Card.Content>
									<Card.Header>{m.alias}</Card.Header>
									<Card.Meta>
										<FormattedMessage id={m.machineType} />
									</Card.Meta>
									<Card.Description>
										<FormattedMessage id={m.status || m.currentStatus} />
									</Card.Description>
								</Card.Content>
								<Card.Content extra>
									<Button primary fluid onClick={() => setSelectedMachine(m)}>
										<FormattedMessage id="Select Machine" />
									</Button>
								</Card.Content>
							</Card>
						))}
					</Card.Group>
				</Segment>
			</Fragment>
			{/* <div>Available Machines To Control for Machine Group {currentMachineGroup.alias}</div>
      {machinesInMachineGroup.length > 0 && (
        <Card.Group>
          {machinesInMachineGroup.map((k) => (
            <Card key={k} onClick={() => setSelectedMachine(k)}>
              <Card.Content>
                <Card.Header>{k.alias}</Card.Header>
                <Card.Meta>
                  <FormattedMessage id={k.machineType} />
                </Card.Meta>
                <Card.Description>
                  <FormattedMessage id={k.status || k.currentStatus} />
                </Card.Description>
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
      )} */}
		</Fragment>
	);
}

export default ManualMachineControl;
