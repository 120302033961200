import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";

const useStyles = createUseStyles({
	content: {
		display: "flex",
		flexDirection: "column",
	},
	cartonsMade: {
		marginBottom: "32px",
		display: "flex",
		flexDirection: "column",
		fontFamily: "Stolzl",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		textAlign: "left",
		color: "#353430",
		"& > span:first-child": {
			fontSize: "48px",
			fontWeight: "bold",
			paddingBottom: "8px",
		},
		"& > span:last-child": {
			fontSize: "24px",
			fontWeight: "500",
		},
	},
});

function CartonsMade({ reportData, dateRange }) {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const [presentationData, setPresentationData] = useState("");

	function mapData(data) {
		let totalCartons = data.totalCartonsProduced;
		setPresentationData(totalCartons);
	}

	useEffect(() => {
		mapData(reportData, dateRange);
	}, [reportData, dateRange]);

	return (
		<div className={classes.content}>
			<div className={classes.cartonsMade}>
				<span>
					{presentationData.toLocaleString(undefined, {
						maximumFractionDigits: 0,
					})}
				</span>
				<span>
					<FormattedMessage id="Cartons Made" />
				</span>
			</div>
		</div>
	);
}

export default CartonsMade;
