import axios from "axios";
import { identityApi } from "@/api/identitySdk.js";

let cancelToken;

export async function getCurrentTimeZoneForSite() {
	if (typeof cancelToken != typeof undefined) {
		cancelToken.cancel("Operation canceled due to new request.");
	}

	cancelToken = axios.CancelToken.source();
	return await identityApi.getTenantTimezone(cancelToken);
}
