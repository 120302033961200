import {
	getExpirationFromLocalStorage,
	logout,
	normalizeRoles,
	setAppInsightsIdentity,
	setFullStoryIdentity,
	UserContext,
} from "@/components/user-context";
import { useCallback, useEffect, useMemo, useState } from "react";
import useOnLoadedEffect from "@/components/use-on-loaded-effect";
import { isDev } from "../utils/isDev";
import { useLocation, useNavigate } from "react-router";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { readLocalStorage } from "@/api/local-storage.js";
import { identityApi } from "@/api/identitySdk.js";
import { decode } from "@/api/jwtFunctions.js";
import { useFeatureFlags } from "@/contexts/feature-flag-context.jsx";
const getCurrentUserInitialState = () => ({ role: [] });

const UserContextProvider = ({ children }) => {
	const navigate = useNavigate();
	const [currentUser, _setCurrentUser] = useState(getCurrentUserInitialState);
	const { flags, setFeatureFlagIdentity } = useFeatureFlags();
	const { pathname } = useLocation();
	const reactPlugin = useAppInsightsContext();

	const setCurrentUser = async (newData) => {
		if (newData === undefined) newData = { role: [] };
		let additionalTenants = newData?.AdditionalTenants ?? currentUser.AdditionalTenants;
		let licenseInfo = {};
		let finalSet;
		let role = [];
		let roles = "";

		if (newData != null && newData.id != null && newData.tid != null && identityApi != null) {
			let response = await identityApi.GetAdditionalTenants({
				UserId: newData.id,
			});

			if (response.success) {
				let newTenants = response.responseData.AdditionalTenants ?? response.responseData.additionalTenants;
				if (newTenants != null) additionalTenants = newTenants;
			}

			let licensingResponse = await identityApi.getLicensingInfo(newData.tid);

			if (licensingResponse.success) {
				let info = licensingResponse.responseData;
				if (info != null) licenseInfo = info;
			}

			if (flags["vaporware-roles-jwt"]) {
				const permissionsResponse = await identityApi.getUserPermissions(newData.id);
				var permissions = permissionsResponse?.data?.permissions ?? [];

				role = permissions.map((str) => str.toLowerCase());
				roles = permissionsResponse?.data?.roles.join(",") ?? "";
			}
		}

		if (flags["vaporware-roles-jwt"]) {
			finalSet = {
				...newData,
				...licenseInfo,
				AdditionalTenants: additionalTenants,
				role,
				roles,
			};
		} else {
			finalSet = {
				...newData,
				...licenseInfo,
				AdditionalTenants: additionalTenants,
			};
		}

		setFeatureFlagIdentity(finalSet);

		_setCurrentUser(finalSet);
		return finalSet;
	};

	const onStorage = useCallback(
		(e) => {
			if (e.key === "BEARER" && e.oldValue !== e.newValue) {
				console.info("Change to BEARER token stored in local storage from another browser window, refreshing page");
				navigate(0);
			}
		},
		[navigate],
	);
	async function requestExpireTime(token) {
		try {
			if (!token) return undefined;
			const resp = await identityApi.getTokenExpiry();
			return resp.responseData;
		} catch {
			return undefined;
		}
	}
	const getTokenFromLocalStorage = async () => {
		const localToken = readLocalStorage("BEARER");
		if (localToken == null || !localToken || !localToken.includes("BEARER")) {
			return undefined;
		} else {
			const decodedToken = decode(localToken.replace("BEARER ", ""));
			decodedToken.token = localToken;

			await setFullStoryIdentity(decodedToken);
			setAppInsightsIdentity(decodedToken);

			const token = normalizeRoles(decodedToken);
			let expiresInSeconds = await requestExpireTime(localToken);
			if (!expiresInSeconds) {
				//api could have failed, check localstorage
				expiresInSeconds = getExpirationFromLocalStorage();
			}

			if (expiresInSeconds <= 0) {
				logout("User Context: Invalid token expiration");
				return undefined;
			}
			return token;
		}
	};
	useEffect(() => {
		window.addEventListener("storage", onStorage);

		return () => {
			window.removeEventListener("storage", onStorage);
		};
	}, [onStorage]);

	useOnLoadedEffect(() => {
		const getToken = async () => {
			const token = await getTokenFromLocalStorage();
			await setCurrentUser(token);
			let unauthenticatedRoutes = [
				"/universal-login",
				"/login-2phase",
				"/forgot-password",
				"/complete-password-reset",
				"/sso-error",
			];

			if (!token && !pathname?.endsWith("logged-out")) {
				if (unauthenticatedRoutes.includes(pathname?.toLowerCase())) {
					navigate(pathname?.toLowerCase());
				} else if (isDev() && pathname?.toLowerCase().includes("/dojo")) navigate("/dojo");
				else navigate("/login");
			}
		};

		getToken();
	});

	useEffect(() => {
		if (currentUser) {
			const appInsights = reactPlugin?.getAppInsights();
			if (appInsights) {
				const handler = appInsights.addTelemetryInitializer((env) => {
					env.data = env.data || {};

					env.data["company"] = currentUser.tname;
					env.data["isPacksizeUser"] = currentUser.email?.toLowerCase().endsWith("@packsize.com");
				});
				return () => handler.remove();
			}
		}
	}, [reactPlugin, currentUser]);

	const userContextValue = useMemo(
		() => ({ currentUser, setCurrentUser, logout }),
		[currentUser, setCurrentUser, flags],
	);

	return <UserContext.Provider value={userContextValue}>{children}</UserContext.Provider>;
};

export default UserContextProvider;
